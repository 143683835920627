import { Lang } from '../../../../models'
import defaultLang from '../../../lang/fr'
import { tipsFr } from './tipsContent'

const LorealFr: Lang = {
    ...defaultLang,
    authentication: {
        title: "It's time to act",
        description: [
            "Chez L'Oréal, nous nous engageons à réduire notre empreinte environnementale dans tous les aspects de notre activité, y compris de notre Digital Workplace.",
            'Greet by Lecko vous aidera à mesurer et à comprendre votre empreinte environnementale individuelle et à prendre des mesures pour adopter des habitudes de travail plus durables.',
            ' ',
            'We all have a role to play !',
        ],
        buttonLabel: 'Se connecter avec Microsoft 365',
    },
    presentation: {
        title: "It's time to act",
        description: [
            "Chez L'Oréal, nous nous engageons à réduire notre empreinte environnementale dans tous les aspects de notre activité, y compris de notre Digital Workplace.",
            'Greet by Lecko vous aidera à mesurer et à comprendre votre empreinte environnementale individuelle et à prendre des mesures pour adopter des habitudes de travail plus durables.',
            ' ',
            'We all have a role to play !',
        ],
    },
    welcomePopupMessage: [
        "Bienvenue sur Greet. Dans le cadre des politiques de réduction de l'empreinte environnementale du Groupe, vous pouvez consulter votre empreinte individuelle, et être invité à participer à des challenges. Votre adresse email professionnelle et vos données d'usage des environnements Microsoft seront alors utilisés.",
        "Vos données seront conservées 2 ans et vous seul aurez accès à vos indicateurs. Des indicateurs d'équipe seront aussi élaborés sur la base de données agrégées non nominatives.",
        'Vous pourrez à tout moment supprimer votre compte sur la plateforme, et vous disposez de droits indiqués sur la Politique de Protection des données des collaborateurs'
    ],
    welcomePopup: {
        title: 'Informations',
        confirmBtn: "J'accepte",
    },
    webinar: {
        ...defaultLang.webinar,
        title: 'Formation',
        startAt: '',
        contentTitle:
            'Nous avons tous un rôle à jouer ! Vous ne savez pas par où commencer ? Participez à une session de formation pour apprendre à réduire votre empreinte numérique.',
        description: '',
        registerationUrl: 'https://qrco.de/bf4DPa',
        register: 'En savoir plus',
    },
    home: {
        ...defaultLang.home,
        blog: {
            ...defaultLang.home.blog,
            title: 'Plus d’informations',
        },
    },
    goal: {
        ...defaultLang.goal,
        tips: {
            title: 'Tips',
            readMore: 'Plus de détails',
        },
        individual: {
            ...defaultLang.goal.individual,
            deleteFolderProposal: {
                ...defaultLang.goal.individual.deleteFolderProposal,
                title: 'Nettoyer votre Digital Workplace'
            }
        }
    },
    legalInformations: {
        ...defaultLang.legalInformations,
        title: 'Informations légales',
        paragraphes: [
            {
                title: 'Traitement des données à caractère personnel',
                content: `L’application GreeT est hébergée par la société Lecko.
                L’analyse des activités sur l’environnement Microsoft 365 est réalisée sur des données pseudonymisées et agrégées au travers d'indicateurs dans des groupes dits "équipes". Seuls les membres de ces équipes et l'administrateur de la plateforme peuvent voir ces indicateurs.
                Pour son fonctionnement, elle enregistre l’email, le prénom, nom et avatar de l’utilisateur transmis par Microsoft lors de la connexion. Seul l'utilisateur voit ses indicateurs.
                L'administrateur dispose d'un suivi des challenges, badges remportés et progrès réalisés par chaque utilisateur de l'application. Afin d’améliorer le produit, l’historique des connexions sur l’application est également enregistré.
                L’utilisateur peut demander à consulter ses informations ou à les supprimer en envoyant un email pendant la durée du pilote à :
                Arnaud Rayrole, Lecko, 64 rue des Archives 75003 Paris ou par email à : arayrole@lecko.fr
                `,
            },
            {
                title: 'Délai de conservation des données',
                content: `Les données des utilisateurs sont conservées pendant toute la durée d’activation de leur compte et au maximum un mois après la suppression de leur compte. Chaque utilisateur peut supprimer son compte depuis l'interface.
                Dans tous les cas de figure, l’ensemble des données de l’application seront supprimées dans les 30 jours suivant la fin du service GreeT.`,
            },
        ],
        cgu: {
            title: "Conditions générales d'utilisation",
            link: 'Télécharger les CGU (pdf)',
        },
        charter: {
            title: "Charte d'utilisation des données personnelles",
            link: "Télécharger la charte d'utilisation des données personelles (pdf)",
        },
    },
    tipsContent: { ...tipsFr },
    welcomeBack: {
        ...defaultLang.welcomeBack,
        footerText: 'Avec Greet, chaque effort est rendu visible !',
    },
}

export default LorealFr
