import classNames from 'classnames'
import { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

const useInfoBulleStyle = (translateX: string, translateY: string) =>
    createUseStyles({
        infoBulle: {
            cursor: 'pointer',
            '&:hover $infoBulleContent': {
                visibility: 'visible',
            },
        },
        infoBulleContent: {
            visibility: 'hidden',
            position: 'absolute',
            transform: `translate(${translateX},${translateY})`,
            zIndex: 10,
        },
        infoBulleText: {
            maxWidth: '180px',
            padding: '5px 10px',
            borderRadius: 'var(--gd-border-normal-radius)',
            textAlign: 'center',
            fontSize: 'var(--gd-size-tiny-body-text)',
            fontWeight: 'normal',
            color: 'var(--gd-text-dark-color)',
            textDecorationLine: 'none',
            backgroundColor: 'var(--gd-main-app-background-color)',
            boxShadow: '0px 0px 15px 5px rgba(0,0,0,0.15);',
        },
        infoBulleComponent: {
            borderRadius: 'var(--gd-border-normal-radius)',
            boxShadow: '0px 0px 15px 5px rgba(0,0,0,0.15);',
        }
    })()

interface InfoBulleProps {
    text?: string
    component?: ReactNode
    translateX?: string
    translateY?: string
    children: ReactNode
}

const InfoBulle = ({ text, translateX = '0%', translateY = '-100%', component, children }: InfoBulleProps) => {
    const styles = useInfoBulleStyle(translateX, translateY)
    if (text == null && component == null) {
        return <>{children}</>
    }

    return (
        <div className={styles.infoBulle}>
            {text != null ? <div className={classNames([styles.infoBulleContent, styles.infoBulleText])}>{text}</div> : null}
            {component != null ? <div className={classNames([styles.infoBulleContent, styles.infoBulleComponent])}>{component}</div> : null}
            {children}
        </div>
    )
}

export default InfoBulle
