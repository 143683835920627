import WebinarImg from '../../assets/illustration/Webinar.png'
import SwissLifeLogo from '../../components/headers/SwissLifeLogo'
import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import { Customer, EGoal } from '../../models'
import { BrickTypes } from '../../models/Brick'
import ECoachingEvent from '../../models/coaching/ECoachingEvent'
import QVTIndividualGoal from '../../pages/QVTIndividualGoal'
import swisslifeEn from './cutomerLangs/swisslife/swisslifeEn'
import swisslifeFr from './cutomerLangs/swisslife/swisslifeFr'
import { defaultBlogs, defaultBricks, defaultBugReport, defaultCoachingMeeting } from './defaultCustomerValues'

const swisslife: Customer = {
    name: 'swisslife',
    platformName: 'swisslife_fr',
    logo: SwissLifeLogo,
    QVTIndividualGoal: QVTIndividualGoal,
    bricks: [...defaultBricks].map((brick) => {
        if (brick.type === BrickTypes.EMAIL) return { ...brick, name: 'Emails' }
        return brick
    }),
    theme: {
        primaryColor: '#D82034', //                                          --gd-primary-color
        primaryDarkColor: '#A11C36', //                                      --gd-primary-dark-color
        secondaryColor: '#808080', //                                        --gd-secondary-color
        secondaryDarkColor: '#333333', //                                    --gd-secondary-dark-color
        tertiaryColor: '#808080', //                                         --gd-tertiary-color

        borderSmallRadius: '15px', //                                        --gd-border-small-radius
        borderNormalRadius: '15px', //                                       --gd-border-normal-radius
        borderBigRadius: '15px', //                                          --gd-border-big-radius

        positiveColor: '#607E46', //                                         --gd-positive-color
        intermediaryColor: '#808080', //                                     --gd-intermediary-color
        negativeColor: '#C45417', //                                         --gd-negative-color

        primaryButtonColor: 'var(--gd-primary-color)', //                    --gd-primary-button-color
        secondaryButtonColor: 'var(--gd-secondary-color)', //                --gd-secondary-button-color
        dangerButtonColor: 'var(--gd-negative-color)', //                    --gd-danger-button-color

        primaryButtonTextColor: 'var(--gd-text-clear-color)', //                --gd-primary-button-text-color
        secondaryButtonTextColor: 'var(--gd-text-clear-color)', //              --gd-secondary-button-text-color
        dangerButtonTextColor: 'var(--gd-text-clear-color)', //                 --gd-secondary-button-text-color

        mainAppBackgroundColor: '#F2F3F3', //                                --gd-main-app-background-color
        mainAppShadowedContent: 'rgb(0 0 0 / 15%) 0px -15px 15px 0px', //    --gd-main-app-shadowed-content
        mainActiveTabBackgroundColor: '#F2F3F3', //                          --gd-main-active-tab-background-color
        mainActiveTabColor: 'var(--gd-secondary-color)', //                    --gd-main-active-tab-color
        mainPopupBackgroundColor: '#F2F3F3', //                              --gd-main-popup-background-color

        blogsPrimaryColor: 'var(--gd-secondary-color)', //                   --gd-blogs-primary-color
        blogsPrimaryDarkColor: 'var(--gd-primary-color)', //                 --gd-blogs-primary-color
        blogsSecondaryColor: 'var(--gd-primary-color)', //                   --gd-blogs-secondary-color

        blogsPrimaryTextColor: 'var(--gd-text-clear-color)', //                --gd-blogs-primary-text-color
        blogsPrimaryDarkTextColor: 'var(--gd-text-clear-color)', //            --gd-blogs-primary-dark-text-color
        blogsSecondaryTextColor: 'var(--gd-text-clear-color)', //              --gd-blogs-secondary-text-color

        backgroundClearColor: '#FFFFFF', //                                  --gd-background-clear-color
        backgroundDarkColor: '#E5E5E5', //                                   --gd-background-dark-color

        textClearColor: '#FFFFFF', //                                        --gd-text-clear-color
        textDarkColor: '#1C1B1F', //                                         --gd-text-dark-color
        textTertiaryColor: '#FFFFFF', // same value as gd-text-clear-color //  --gd-text-tertiary-color
        textMainSubTitleColor: '#3E9097', //                                 --gd-text-main-subtitle-color
        bigTextDarkColor: '#1C1B1F', //                                      --gd-big-text-dark-color

        brickEmailColor: '#808080', //                                       --gd-brick-email-color
        brickEmailActiveColor: '#F2F3F3', //                                 --gd-brick-email-active-color
        brickOneDriveColor: '#15847B', //                                    --gd-brick-onedrive-color
        brickOneDriveActiveColor: '#FFFFFF', //                              --gd-brick-onedrive-active-color
        brickTeamsColor: '#83CEC5', //                                       --gd-brick-teams-color
        brickTeamsActiveColor: '#F2F3F3', //                                 --gd-brick-teams-active-color
        brickSharepointColor: '#267379', //                                  --gd-brick-sharepoint-color
        brickSharepointActiveColor: '#F0F6FF', //                            --gd-brick-sharepoint-active-color
        brickVisionColor: '#58BDC5', //                                      --gd-brick-vision-color
        brickVisionActiveColor: '#58BDC5', //                                --gd-brick-vision-active-color
        brickChatColor: '#D82034', //                                        --gd-brick-chat-color
        brickGmailColor: '#002F7D', //                                       --gd-brick-gmail-color
        brickGmailActiveColor: '#F0F6FF', //                                 --gd-brick-gmail-active-color
        brickGDriveColor: '#14675A', //                                      --gd-brick-gdrive-color
        brickGDriveActiveColor: '#F0F6FF', //                                --gd-brick-gdrive-active-color
        brickSharedGDriveColor: '#302E9D', //                                --gd-brick-shared-gdrive-color
        brickSharedGDriveActiveColor: '#F0F6FF', //                          --gd-brick-shared-gdrive-active-color
        brickCopiloteColor: '#A86EDD', //                                    --gd-brick-copilote-color'
        brickCopiloteActiveColor: '#1C1B1F', //                              --gd-brick-copilote-active-color

        goalTextColor: 'var(--gd-text-dark-color)', //                       --gd-goal-text-color
        goalFillColor: '#FFF', //                                            --gd-goal-fill-color
        goalCarbonFootprintColor: '#D82034', //                              --gd-goal-carbon-footprint-color
        goalInfobisityColor: '#D82034', //                                   --gd-goal-infobesity-color
        goalDeconnectionTimeColor: '#D82034', //                             --gd-goal-deconnection-time-color

        badgeTextColor: 'var(--gd-text-dark-color)', //                      --gd-badge-text-color
        badgeFillColor: '#FFF', //                                           --gd-badge-fill-color
        badgeMailsOnDietColor: '#D82034', //                                 --gd-badge-mails-on-diet-color
        badgeMailsOnDietLineColor: '#D82034', //                             --gd-badge-mails-on-diet-line-color
        badgeMailsOnDietFillColor: '#D82034', //                             --gd-badge-mails-on-diet-fill-color
        badgeCleanUpColor: '#D82034', //                                     --gd-badge-cleanup-color
        badgeCleanUpLineColor: '#D82034', //                                 --gd-badge-cleanup-line-color
        badgeCleanUpFillColor: '#D82034', //                                 --gd-badge-cleanup-fill-color
        badgePlanetFriendlyColor: '#D82034', //                              --gd-badge-planet-friendly
        badgePlanetFriendlyLineColor: '#D82034', //                          --gd-badge-planet-line-friendly
        badgePlanetFriendlyFillColor: '#D82034', //                          --gd-badge-planet-fill-friendly
        badgeInternAttachmentColor: '#D82034', //                            --gd-badge-intern-attachment-color
        badgeInternAttachmentLineColor: '#D82034', //                        --gd-badge-intern-attachment-line-color
        badgeInternAttachmentFillColor: '#D82034', //                        --gd-badge-intern-attachment-fill-color
        badgeMailShotColor: '#D82034', //                                    --gd-badge-mailshot-color
        badgeMailShotLineColor: '#D82034', //                                --gd-badge-mailshot-line-color
        badgeMailShotFillColor: '#D82034', //                                --gd-badge-mailshot-fill-color
        badgeImageHuntColor: '#D82034', //                                   --gd-badge-imagehunt-color
        badgeImageHuntLineColor: '#D82034', //                               --gd-badge-imagehunt-line-color
        badgeImageHuntFillColor: '#D82034', //                               --gd-badge-imagehunt-fill-color
        badgeVideoHuntColor: '#D82034', //                                   --gd-badge-video-hunt-color
        badgeVideoHuntLineColor: '#D82034', //                               --gd-badge-video-hunt-line-color
        badgeVideoHuntFillColor: '#D82034', //                               --gd-badge-video-hunt-fill-color
        loaderColors: ['#D82034', '#E5E5E5', '#E5E5E5', '#F2F3F3', '#E5E5E5'],

        emailSendingRangeLessThanTen: '#9BCDD0', //                            --gd-email-sending-range-stick-less-than-ten-fill-color
        emailSendingRangeBetweenTenAndThirty: '#59ABB1', //                    --gd-email-sending-range-stick-beetwen-ten-and-thirty-fill-color
        emailSendingRangeMoreThanThirty: '#2D5659', //                          --gd-email-sending-range-stick-more-than-thirty-fill-color

        emailSendingRangeSmallRange: '#9BCDD0', //                             --gd-email-sending-range-small-range-color
        emailSendingRangeMediumRange: '#59ABB1', //                            --gd-email-sending-range-medium-range-color
        emailSendingRangeLargeRange: '#4d7679', //                             --gd-email-sending-range-large-range-color
        emailSendingRangeHeightRange: '#2D5659', //                            --gd-email-sending-range-height-range-color
    },
    goals: [EGoal.CARBON_FOOTPRINT, EGoal.QVT],
    goalPagesLayout: {
        coachingOnCollective: {
            tips: true,
            training: true,
            coaching: true,
        },
    },
    contact: {
        support: {
            name: '',
            mail: 'support-greet-swisslife@lecko.fr',
        },
        hasLinkSupport: false,
        link: '',
    },
    legalInformation: {
        cgu: 'https://leckogreet.blob.core.windows.net/lecko/Greet_CGU_Lecko_Oct_2023.pdf',
        charter: 'https://leckogreet.blob.core.windows.net/lecko/Greet_Charte_de_protection_des_donnees_personnelles_Oct_2023.pdf',
    },
    customerLang: {
        fr: swisslifeFr,
        en: swisslifeEn,
    },
    webinarImg: WebinarImg,
    bugReport: defaultBugReport,
    disablePwa: true,
    defaultCoachingMeeting: defaultCoachingMeeting,
    coachingEvent: ECoachingEvent.ANNOUNCEMENT,
    featuring: [GreetFeaturingTarget.RANKING, GreetFeaturingTarget.RANKING_VICTORIES, GreetFeaturingTarget.DRIVE_FOLDER_DELETION],
    blogs: defaultBlogs
}

export default swisslife
