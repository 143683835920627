import { RBFCLogo } from '../../components/headers/RBFCLogo'
import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import { Customer } from '../../models'
import lecko from './lecko'

const rbfc: Customer = {
    ...lecko,
    name: 'rbfc',
    disablePwa: true,
    logo: RBFCLogo,
    featuring: [GreetFeaturingTarget.RANKING, GreetFeaturingTarget.RANKING_VICTORIES, GreetFeaturingTarget.DRIVE_FOLDER_DELETION, GreetFeaturingTarget.DRIVE_VERSION],
    theme: {
        primaryColor: '#000000', //                                            --gd-primary-color
        primaryDarkColor: '#000000', //                                        --gd-primary-dark-color
        secondaryColor: '#F4C500', //                                          --gd-secondary-color
        secondaryDarkColor: '#D2AA03', //                                      --gd-secondary-dark-color
        tertiaryColor: '#F4C500', //                                           --gd-tertiary-color

        borderSmallRadius: '10px', //                                          --gd-border-small-radius
        borderNormalRadius: '20px', //                                         --gd-border-normal-radius
        borderBigRadius: '50px', //                                            --gd-border-big-radius

        positiveColor: '#4DAD33', //                                           --gd-positive-color
        intermediaryColor: '#F4991D', //                                       --gd-intermediary-color
        negativeColor: '#ED2C2C', //                                           --gd-negative-color

        primaryButtonColor: 'var(--gd-primary-color)', //                      --gd-primary-button-color
        secondaryButtonColor: 'var(--gd-secondary-color)', //                  --gd-secondary-button-color
        dangerButtonColor: 'var(--gd-negative-color)', //                      --gd-danger-button-color

        primaryButtonTextColor: 'var(--gd-text-dark-color)', //                --gd-primary-button-text-color
        secondaryButtonTextColor: 'var(--gd-text-dark-color)', //              --gd-secondary-button-text-color
        dangerButtonTextColor: 'var(--gd-text-dark-color)', //                 --gd-secondary-button-text-color

        mainAppBackgroundColor: '#FFFFFF', //                                  --gd-main-app-background-color
        mainAppShadowedContent: 'rgb(0 0 0 / 8%) 0px -15px 15px 0px', //       --gd-main-app-shadowed-content
        mainActiveTabBackgroundColor: 'var(--gd-background-clear-color)', //   --gd-main-active-tab-background-color
        mainActiveTabColor: 'var(--gd-primary-color)', //                      --gd-main-active-tab-color
        mainPopupBackgroundColor: '#FFFFFF', //                                --gd-main-popup-background-color

        blogsPrimaryColor: 'var(--gd-secondary-color)', //                     --gd-blogs-primary-color
        blogsPrimaryDarkColor: 'var(--gd-secondary-dark-color)', //            --gd-blogs-primary-color
        blogsSecondaryColor: 'var(--gd-primary-color)', //                     --gd-blogs-secondary-color

        blogsPrimaryTextColor: 'var(--gd-text-dark-color)', //                --gd-blogs-primary-text-color
        blogsPrimaryDarkTextColor: 'var(--gd-text-dark-color)', //            --gd-blogs-primary-dark-text-color
        blogsSecondaryTextColor: 'var(--gd-text-clear-color)', //              --gd-blogs-secondary-text-color

        backgroundClearColor: '#F8F8F8', //                                    --gd-background-clear-color
        backgroundDarkColor: '#E7E7E7', //                                     --gd-background-dark-color

        textClearColor: '#FFFFFF', //                                          --gd-text-clear-color
        textDarkColor: '#1C1B1F', //                                           --gd-text-dark-color
        textTertiaryColor: '#1C1B1F', //                                       --gd-text-tertiary-color
        textMainSubTitleColor: 'var(--gd-text-dark-color)', //                 --gd-text-main-subtitle-color
        bigTextDarkColor: 'var(--gd-text-dark-color)', //                      --gd-big-text-dark-color

        brickEmailColor: '#77CAE6', //                                         --gd-brick-email-color
        brickEmailActiveColor: '#F8F8F8', //                                   --gd-brick-email-active-color
        brickOneDriveColor: '#0085CA', //                                      --gd-brick-onedrive-color
        brickOneDriveActiveColor: '#F8F8F8', //                                --gd-brick-onedrive-active-color
        brickTeamsColor: '#BB16A3', //                                         --gd-brick-teams-color
        brickTeamsActiveColor: '#F8F8F8', //                                   --gd-brick-teams-active-color
        brickSharepointColor: '#267379', //                                    --gd-brick-sharepoint-color
        brickSharepointActiveColor: '#F8F8F8', //                              --gd-brick-sharepoint-active-color
        brickVisionColor: '#58BDC5', //                                        --gd-brick-vision-color
        brickVisionActiveColor: '#F8F8F8', //                                  --gd-brick-vision-active-color
        brickChatColor: '#59ABB1', //                                          --gd-brick-chat-color
        brickGmailColor: '#4285f4', //                                         --gd-brick-gmail-color
        brickGmailActiveColor: '#1C1B1F', //                                   --gd-brick-gmail-active-color
        brickGDriveColor: '#FBBC04', //                                        --gd-brick-gdrive-color
        brickGDriveActiveColor: '#1C1B1F', //                                  --gd-brick-gdrive-active-color
        brickSharedGDriveColor: '#9AA0A6', //                                  --gd-brick-shared-gdrive-color
        brickSharedGDriveActiveColor: '#1C1B1F', //                            --gd-brick-shared-gdrive-active-color

        brickCopiloteColor: '#F38F00', //                                      --gd-brick-copilote-color
        brickCopiloteActiveColor: '#F8F8F8', //                                --gd-brick-copilote-active-color

        goalTextColor: 'var(--gd-text-dark-color)', //                         --gd-goal-text-color
        goalFillColor: '#FFF', //                                              --gd-goal-fill-color
        goalCarbonFootprintColor: '#00A191', //                                --gd-goal-carbon-footprint-color
        goalInfobisityColor: '#F38F00', //                                     --gd-goal-infobesity-color
        goalDeconnectionTimeColor: '#0085CA', //                               --gd-goal-deconnection-time-color

        badgeTextColor: 'var(--gd-text-dark-color)', //                        --gd-badge-text-color
        badgeFillColor: '#FFF', //                                             --gd-badge-fill-color
        badgeMailsOnDietColor: '#0085CA', //                                   --gd-badge-mails-on-diet-color
        badgeMailsOnDietLineColor: '#0085CA', //                               --gd-badge-mails-on-diet-line-color
        badgeMailsOnDietFillColor: '#0085CA', //                               --gd-badge-mails-on-diet-fill-color
        badgeCleanUpColor: '#00A191', //                                       --gd-badge-cleanup-color
        badgeCleanUpLineColor: '#00A191', //                                   --gd-badge-cleanup-line-color
        badgeCleanUpFillColor: '#00A191', //                                   --gd-badge-cleanup-fill-color
        badgePlanetFriendlyColor: '#00A191', //                                --gd-badge-planet-friendly
        badgePlanetFriendlyLineColor: '#00A191', //                            --gd-badge-planet-line-friendly
        badgePlanetFriendlyFillColor: '#00A191', //                            --gd-badge-planet-fill-friendly
        badgeInternAttachmentColor: '#F38F00', //                              --gd-badge-intern-attachment-color
        badgeInternAttachmentLineColor: '#F38F00', //                          --gd-badge-intern-attachment-line-color
        badgeInternAttachmentFillColor: '#F38F00', //                          --gd-badge-intern-attachment-fill-color
        badgeMailShotColor: '#BB16A3', //                                      --gd-badge-mailshot-color
        badgeMailShotLineColor: '#BB16A3', //                                  --gd-badge-mailshot-line-color
        badgeMailShotFillColor: '#BB16A3', //                                  --gd-badge-mailshot-fill-color
        badgeImageHuntColor: '#BB16A3', //                                     --gd-badge-imagehunt-color
        badgeImageHuntLineColor: '#BB16A3', //                                 --gd-badge-imagehunt-line-color
        badgeImageHuntFillColor: '#BB16A3', //                                 --gd-badge-imagehunt-fill-color
        badgeVideoHuntColor: '#0085CA', //                                     --gd-badge-video-hunt-color
        badgeVideoHuntLineColor: '#0085CA', //                                 --gd-badge-video-hunt-line-color
        badgeVideoHuntFillColor: '#0085CA', //                                 --gd-badge-video-hunt-fill-color
        loaderColors: ['#000000', '#F4C500', '#F4C500', '#FFFFFF', '#F4C500'],

        emailSendingRangeLessThanTen: '#9BCDD0', //                            --gd-email-sending-range-stick-less-than-ten-fill-color
        emailSendingRangeBetweenTenAndThirty: '#59ABB1', //                    --gd-email-sending-range-stick-beetwen-ten-and-thirty-fill-color
        emailSendingRangeMoreThanThirty: '#2D5659', //                         --gd-email-sending-range-stick-more-than-thirty-fill-color

        emailSendingRangeSmallRange: '#d6d6d6', //                             --gd-email-sending-range-small-range-color
        emailSendingRangeMediumRange: '#939393', //                            --gd-email-sending-range-medium-range-color
        emailSendingRangeLargeRange: '#555555', //                              --gd-email-sending-range-large-range-color
        emailSendingRangeHeightRange: '#1C1B1F', //                            --gd-email-sending-range-height-range-color
    },
}

export default rbfc
