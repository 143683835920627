import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useInstance, useUser } from '../contexts'

import { BrickTypes } from '../models/Brick'
import Payload from '../models/Payload'
import BrickDoughnut from './carbonfootprint/BrickDoughnut'
import BrickDoughnutDetails from './carbonfootprint/BrickDoughnutDetails'
import BrickDoughnutLegend from './carbonfootprint/BrickDoughnutLegend'
import { periodTitle, substractDays } from './dateUtils'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import Title from './Title'

interface CarbonImpactProps {
    title: string
    payload?: Payload
}

const useStyles = () =>
    createUseStyles({
        carbonFootprint: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignSelf: 'center',
            gap: '1.5rem',
        },
        doughnutContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '1rem',
        },
        doughnutMobileContainer: {
            display: 'none',
        },
        doughnutAndLegend: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '10px',
        },
        title: {
            display: 'block',
        },
        mobileTitle: {
            display: 'none',
        },
        mainTitle: {
            display: 'none',
        },
        rangeTitle: {
            display: 'none',
        },
        [MediaQueryBreakpoints.MOBILE]: {
            carbonFootprint: {
                padding: '10px 0',
            },
            title: {
                display: 'none',
            },
            mobileTitle: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: 'var(--gd-secondary-dark-color)',
                fontWeight: 'bold',
                gap: '10px',
            },
            mainTitle: {
                display: 'block',
                fontSize: '22px',
                lineHeight: '25px',
            },
            rangeTitle: {
                display: 'block',
                fontSize: 'var(--gd-size-highlighted-small-title)',
            },
            doughnutContainer: {
                display: 'none',
            },
            doughnutMobileContainer: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '1rem',
            },
            doughnutAndLegend: {
                flexDirection: 'row-reverse',
            },
        },
        [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
            doughnutContainer: {
                flexDirection: 'column',
            },
        },
    })()

export default function CarbonFootprint({ payload, title }: CarbonImpactProps) {
    const { customer } = useInstance()
    const { lang } = useUser()
    const [activeBrick, setActiveBrick] = useState<BrickTypes>(customer.bricks[0].type)
    const styles = useStyles()

    if (payload == null) {
        return null
    }

    const titleRange = periodTitle(substractDays(payload.calculationDate, 30), substractDays(payload.calculationDate, 1), lang)

    return (
        <div className={styles.carbonFootprint}>
            <div className={styles.title}>
                <Title title={title} subTitle={titleRange} />
            </div>
            <div className={styles.mobileTitle}>
                <div className={styles.mainTitle}>{lang.goal.carbonFootprint.title}</div>
                <div className={styles.rangeTitle}>{titleRange}</div>
            </div>

            <div className={styles.doughnutContainer}>
                <BrickDoughnutLegend bricks={customer.bricks} payload={payload} activeBrick={activeBrick} setActiveBrick={setActiveBrick} />
                <BrickDoughnut bricks={customer.bricks} payload={payload} activeBrick={activeBrick} setActiveBrick={setActiveBrick} />
                <BrickDoughnutDetails bricks={customer.bricks} payload={payload} activeBrick={activeBrick} />
            </div>
            <div className={styles.doughnutMobileContainer}>
                <div className={styles.doughnutAndLegend}>
                    <BrickDoughnutLegend bricks={customer.bricks} payload={payload} activeBrick={activeBrick} setActiveBrick={setActiveBrick} />
                    <BrickDoughnut bricks={customer.bricks} payload={payload} activeBrick={activeBrick} setActiveBrick={setActiveBrick} />
                </div>
                <BrickDoughnutDetails bricks={customer.bricks} payload={payload} activeBrick={activeBrick} />
            </div>
        </div>
    )
}
