import { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { useInstance, useUser } from '../../contexts'
import { EGoal } from '../../models'
import UserPayload from '../../models/UserPayload'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import Avatar from '../headers/Avatar'
import InfoBulle from '../share/Infobulle'
import { numberFormatter } from '../utils'

const usePersonalScoringStyle = createUseStyles({
    personalScoring: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '360px',
        minWidth: '360px',
        height: '280px',
        maxHeight: '280px',
        borderRadius: 'var(--gd-border-normal-radius)',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0px',
    },

    personalScoringAvatarContaier: {
        position: 'relative',
        height: '50px',
    },
    personalScoringAvatar: {
        width: '82px!important',
        height: '82px!important',
        border: '3px solid var(--gd-background-clear-color)',
        cursor: 'unset!important',
    },
    personalScoringName: {
        color: 'var(--gd-text-dark-color)',
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
    },
    personalScoringContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        borderRadius: 'var(--gd-border-normal-radius)',
        backgroundColor: 'var(--gd-background-clear-color)',
        fontSize: 'var(--gd-size-small-body-text)',
        color: 'var(--gd-text-dark-color)',
        gap: '8px',
    },
    personalScoringCarbonContent: {
        paddingBottom: '15px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    personalScoringScore: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
    },
    personalScoringScoreIcon: {
        width: '21px',
        height: '21px',
    },
    personalScoringScorePoints: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        backgroundColor: 'var(--gd-main-app-background-color)',
        padding: '5px 10px',
        borderRadius: '25px',
        fontWeight: 'bold',
    },
    personalScoringScorePointsInformation: {
        width: '15px',
        height: '15px',
    },
    personalScoringScorePositionContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'center',
        gap: '3px',
        fontWeight: 'bold',
    },
    personalScoringScorePosition: {
        fontSize: 'var(--gd-size-big-number)',
        color: 'var(--gd-secondary-color)',
    },
    personalScoringScoreExposant: {
        fontSize: 'var(--gd-size-small-body-text)',
        color: 'var(--gd-secondary-color)',
    },
    personalScoringScoreMaxPosition: {
        fontSize: 'var(--gd-size-body-text)',
    },
    personalScoringScoreLastPositionContent: {
        backgroundColor: 'var(--gd-main-app-background-color)',
        padding: '5px 20px',
        borderRadius: '25px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'center',
        gap: '3px',
    },
    personalScoringScoreLastPositionNoContent: {
        padding: '5px 20px',
        height: '30px',
    },
    personalScoringScoreLastPosition: {
        fontWeight: 'bold',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        personalScoring: {
            maxWidth: '100%',
            minWidth: '100%',
        },
    },
})

interface PersonalScoringProps {
    userPayload: UserPayload
    eGoal: EGoal
}

const PersonalScoring = ({ userPayload, eGoal }: PersonalScoringProps) => {
    const { user, lang } = useUser()
    const styles = usePersonalScoringStyle()
    const { score, rank, maxRank } = eGoal === EGoal.CARBON_FOOTPRINT ? userPayload?.ranking ?? {} : userPayload?.qvtRanking ?? {}
    const { score: scoreJ30, rank: rankJ30, maxRank: maxRankJ30 } = eGoal === EGoal.CARBON_FOOTPRINT ? userPayload?.ranking ?? {} : userPayload?.qvtRankingJ30 ?? {}
    const ScoringContent = useMemo(() => (eGoal === EGoal.CARBON_FOOTPRINT ? CarbonPersonelCarbonScoringContent : QVCTPersonelScoringContent), [lang, eGoal, userPayload])

    return (
        <div className={styles.personalScoring}>
            <div className={styles.personalScoringAvatarContaier}>
                <Avatar className={styles.personalScoringAvatar} />
            </div>
            <div className={styles.personalScoringContent}>
                <div className={styles.personalScoringName}>{user?.firstName}</div>
                <ScoringContent score={score} rank={rank} maxRank={maxRank} scoreJ30={scoreJ30} rankJ30={rankJ30} maxRankJ30={maxRankJ30} />
            </div>
        </div>
    )
}

const CarbonPersonelCarbonScoringContent = ({ score, rank, maxRank }: any) => {
    const { lang } = useUser()
    const styles = usePersonalScoringStyle()
    const properties = useMemo(
        () => ({
            personalScoringScoreIcon: IconAssets.CarbonFootprintIcon('var(--gd-positive-color)'),
            pointsDescription: lang.personalScoring.pointsDescription.carbonFootprint,
            personalScoringScorePoints: `${numberFormatter(score)} pts`,
            tooltipText: lang.personalScoring.tooltip.carbonFootprint,
            positionDescription: lang.personalScoring.positionDescription.carbonFootprint,
        }),
        [lang, score, rank, maxRank],
    )
    return (
        <div className={styles.personalScoringCarbonContent}>
            <div className={styles.personalScoringScore}>
                <div className={styles.personalScoringScoreIcon}>{properties.personalScoringScoreIcon}</div>
                <div>{properties.pointsDescription}</div>
                <div className={styles.personalScoringScorePoints}>
                    {properties.personalScoringScorePoints}
                    <InfoBulle text={properties.tooltipText}>
                        <div className={styles.personalScoringScorePointsInformation}>
                            <IconAssets.Information />
                        </div>
                    </InfoBulle>
                </div>
            </div>
            <div>{properties.positionDescription}</div>
            <div className={styles.personalScoringScorePositionContent}>
                <div className={styles.personalScoringScorePosition}>
                    <span>{rank}</span>
                    <sup className={styles.personalScoringScoreExposant}>{lang.personalScoring.positionDecorator(rank)}</sup>
                </div>
                <div className={styles.personalScoringScoreMaxPosition}>
                    / {maxRank} {lang.personalScoring.maxPositonDescription}
                </div>
            </div>
            <div className={styles.personalScoringScoreLastPositionNoContent}></div>
        </div>
    )
}

const useQVCTPersonelScoringContentStyles = createUseStyles({
    qvctPersonelScoringContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        marginBottom: '8px',
    },
    qvctPersonelScoringContentMessage: {
        fontWeight: 'bold',
        fontSize: '14px',
    },
    qvctPersonelScoringContentScore: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '12px',
    },
    qvctPersonelScoringContentScoreInfo: {
        width: '105px',
    },
    qvctPersonelScoringContentChart: {
        position: 'relative',
    },
    scoreChartIcon: {
        position: 'absolute',
        top: '-10px',
        width: '85px',
        height: '85px',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2px',
    },
    scoreChartPosition: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontWeight: 'bold',
    },
    scoreChartPositionScore: {
        color: 'var(--gd-primary-color)',
        fontSize: '26px',
        borderBottom: '2px solid var(--gd-text-dark-color)',
    },
    scoreChartPositionTotalScore: {
        color: 'var(--gd-text-dark-color)',
        fontSize: '14px',
    },
    scoreChart: {
        width: '85px',
        height: '85px',
    },
    qvctPersonelScoringContentScoreDescription: {
        width: '100%',
        paddingTop: '8px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
    },
    qvctPersonelScoringContentScoreDescriptionIcon: {
        width: '15px',
        height: '15px',
    },
})

const QVCTPersonelScoringContent = ({ score, scoreJ30 }: any) => {
    const { customer } = useInstance()
    const { lang } = useUser()
    const componentStyles = useQVCTPersonelScoringContentStyles()
    const customerTheme = customer.theme
    const scoreDiff = (score ?? 0) - (scoreJ30 ?? 0)
    let scoreDiffText = ''
    if (scoreDiff === 0) {
        scoreDiffText = lang.personalScoring.rankEqual
    } else if (scoreDiff > 0) {
        scoreDiffText = lang.personalScoring.rankUp(Math.abs(scoreDiff))
    } else {
        scoreDiffText = lang.personalScoring.rankDown(Math.abs(scoreDiff))
    }

    return (
        <div className={componentStyles.qvctPersonelScoringContent}>
            <div className={componentStyles.qvctPersonelScoringContentMessage}>{lang.personalScoring.positionDescription.qvt}</div>
            <div className={componentStyles.qvctPersonelScoringContentScore}>
                <div className={componentStyles.qvctPersonelScoringContentChart}>
                    <div className={componentStyles.scoreChartIcon}>
                        <IconAssets.HeartBeating />
                        <div className={componentStyles.scoreChartPosition}>
                            <div className={componentStyles.scoreChartPositionScore}>{score}</div>
                            <div className={componentStyles.scoreChartPositionTotalScore}>100</div>
                        </div>
                    </div>
                    <div className={componentStyles.scoreChart}>
                        <Doughnut
                            data={{
                                datasets: [
                                    {
                                        data: [score ?? 0, 100 - (score ?? 100)],
                                        backgroundColor: [customerTheme.primaryColor, customerTheme.backgroundDarkColor],
                                        borderWidth: 0,
                                    },
                                ],
                            }}
                            options={{
                                cutout: '85%',
                                hover: { mode: undefined },
                                plugins: {
                                    tooltip: {
                                        enabled: false,
                                    },
                                    legend: {
                                        display: false,
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
                <div className={componentStyles.qvctPersonelScoringContentScoreInfo}>{scoreDiffText}</div>
            </div>
            <div className={componentStyles.qvctPersonelScoringContentScoreDescription}>
                {lang.personalScoring.pointsDescription.qvt}
                <InfoBulle text={lang.personalScoring.tooltip.qvt} translateX='-25%'>
                    <div className={componentStyles.qvctPersonelScoringContentScoreDescriptionIcon}>
                        <IconAssets.Information />
                    </div>
                </InfoBulle>
            </div>
        </div>
    )
}

export default PersonalScoring
