import { Row } from '@tanstack/react-table'
import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useCSVDownloader } from 'react-papaparse'
import IconAssets from '../../assets/icon/IconAssets'
import { useInstance, useUser } from '../../contexts'
import { Lang } from '../../models'
import { BadgeType } from '../../models/Badge'
import { useFetch } from '../../services/api/useFetch'
import Title from '../Title'
import { periodTitle, toNumericString } from '../dateUtils'
import { GreetButton } from '../share/GreetButton'
import { DebouncedInputFilter, GreetTable, GreetTableFilterProps, SelectFilter } from '../share/GreetTable'

const useChallengeResultStyle = createUseStyles({
    challengeResults: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
    },
})

const ChallengeResults = () => {
    const styles = useChallengeResultStyle()
    const { lang } = useUser()
    return (
        <div className={styles.challengeResults}>
            <Title title={lang.animationChallenge.tableTitle} />
            <AnimationChallengeResultFilteredTable />
        </div>
    )
}

interface ChallengeResult {
    firstName: string
    lastName: string
    email: string
    cohortId: string
    cohortName: string
    challengeType: BadgeType
    challengeSize: number
    challengeResults: string
    startDate: Date
    endDate: Date
}

const AnimationChallengeResultFilteredTable = () => {
    const { loading, data } = useFetch({ endpoint: 'challenge-results' })
    const { lang } = useUser()
    const [filtersOptions, setFiltersOptions] = useState<any>({
        cohortOptions: [],
        challengeOptions: [],
    })

    useEffect(() => {
        const uniqueCohortOptions = new Map(data?.map(({ cohortId, cohortName }: ChallengeResult) => [cohortId, { label: cohortName, value: cohortName }])).values() ?? []
        const cohortOptions = [{ label: lang.animationChallenge.filters.allCohort, value: '' }, ...uniqueCohortOptions]

        const uniqueChallengeOptions =
            new Map(
                data?.map(({ challengeType }: ChallengeResult) => [
                    challengeType,
                    { label: lang.goal.challenge.label(challengeType), value: lang.goal.challenge.label(challengeType) },
                ]),
            ).values() ?? []
        const challengeOptions = [{ label: lang.animationChallenge.filters.allChallenges, value: '' }, ...uniqueChallengeOptions]
        setFiltersOptions({
            cohortOptions: cohortOptions,
            challengeOptions: challengeOptions,
        })
    }, [lang, data])

    const headers = lang.animationChallenge.tableHeaders
    const columns = [
        {
            id: 'utilisateur',
            header: headers.utilisateur,
            accessorFn: ({ firstName, lastName }: ChallengeResult) => `${firstName ?? 'XXX'} ${lastName ?? 'XXX'}`,
        },
        {
            id: 'email',
            header: headers.email,
            accessorKey: 'email',
        },
        {
            id: 'cohortId',
            header: headers.cohortId,
            accessorKey: 'cohortName',
        },
        {
            id: 'challenge',
            header: headers.challenge,
            accessorFn: ({ challengeType, challengeSize }: ChallengeResult) => challengTypeToString(lang, challengeType, challengeSize),
        },
        {
            id: 'result',
            header: headers.result,
            accessorFn: ({ challengeResults }: ChallengeResult) => `${challengeResults?.length ? challengeResults : headers.gagne}`,
        },
        {
            id: 'dates',
            header: headers.dates,
            accessorFn: ({ startDate, endDate }: ChallengeResult) => periodTitle(startDate, endDate, lang),
            sortingFn: (rowA: Row<ChallengeResult>, rowB: Row<ChallengeResult>): number => {
                return new Date(rowA.original.startDate).getTime() - new Date(rowB.original.startDate).getTime()
            },
        },
    ]

    return (
        <GreetTable
            columns={columns}
            data={data ?? []}
            loading={loading}
            headerComponentFn={(props: any) => (
                <AnimationChallengeResultHeader {...props} cohortOptions={filtersOptions.cohortOptions} challengeOptions={filtersOptions.challengeOptions} />
            )}
        />
    )
}

interface AnimationChallengeResultFiltersProps<T> extends GreetTableFilterProps<T> {
    cohortOptions: any[]
    challengeOptions: any[]
}

const useAnimationChallengeResultHeaderStyle = createUseStyles({
    animationChallengeResultHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '15px',
    },
    headerButton: {
        width: '170px',
        height: '42px',
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'normal',
        padding: '0 10px',
    },
    headerExportButton: {
        border: 'none',
        color: 'var(--gd-text-clear-color)',
        backgroundColor: 'var(--gd-secondary-color)',
        borderRadius: 'var(--gd-border-big-radius)',
        cursor: 'pointer',
    },
})

const AnimationChallengeResultHeader = ({ table, setGlobalFilter, cohortOptions, challengeOptions }: AnimationChallengeResultFiltersProps<any>) => {
    const { customer } = useInstance()
    const { lang } = useUser()
    const styles = useAnimationChallengeResultHeaderStyle()
    const [allFieldsFilter, setAllFieldsFilter] = useState<string>('')
    const { CSVDownloader, Type } = useCSVDownloader()
    const [defaultFilter, setDefaultFilters] = useState<any>({
        cohortFilter: { label: lang.animationChallenge.filters.allCohort, value: '' },
        challengeFilter: { label: lang.animationChallenge.filters.allChallenges, value: '' },
    })
    const [cohortFilter, setCohortFilter] = useState<any>(defaultFilter.cohortFilter)
    const [challengeFilter, setChallengeFilter] = useState<any>(defaultFilter.challengeFilter)

    useEffect(() => {
        setDefaultFilters({
            cohortFilter: { label: lang.animationChallenge.filters.allCohort, value: '' },
            challengeFilter: { label: lang.animationChallenge.filters.allChallenges, value: '' },
        })
        if (cohortFilter?.value === '') {
            setCohortFilter({ label: lang.animationChallenge.filters.allCohort, value: '' })
        }
        if (challengeFilter?.value === '') {
            setChallengeFilter({ label: lang.animationChallenge.filters.allChallenges, value: '' })
        }
    }, [lang.code])

    const columns = table
        .getHeaderGroups()
        .map((headerGroup) => headerGroup.headers.map((header) => header.column))
        .flatMap((element) => element)
    const cohortColumn = columns.find((col) => col.id === 'cohortId')
    const challengColumn = columns.find((col) => col.id === 'challenge')
    const exportDate = toNumericString(new Date()).replaceAll('/', '')
    const exportfilename = `${customer.name}_challenge_results_${exportDate}`

    return (
        <div>
            <div className={styles.animationChallengeResultHeader}>
                <DebouncedInputFilter
                    value={allFieldsFilter ?? ''}
                    onChange={(value) => {
                        const stringValue = String(value)
                        setAllFieldsFilter(stringValue)
                        setGlobalFilter(stringValue)
                    }}
                    placeholder={lang.animationChallenge.filters.search}
                    icon={<IconAssets.SearchIcon />}
                />
                <SelectFilter
                    type='text'
                    value={cohortFilter}
                    placeholder={lang.animationChallenge.filters.cohort}
                    onChange={(selectedValue) => {
                        setCohortFilter(selectedValue)
                        cohortColumn?.setFilterValue(selectedValue.value)
                    }}
                    options={cohortOptions}
                />
                <SelectFilter
                    type='text'
                    value={challengeFilter}
                    placeholder={lang.animationChallenge.filters.challenge}
                    onChange={(selectedValue) => {
                        setChallengeFilter(selectedValue)
                        challengColumn?.setFilterValue(selectedValue.value)
                    }}
                    options={challengeOptions}
                />
                <GreetButton
                    type='secondary'
                    className={styles.headerButton}
                    label={lang.animationChallenge.filters.deleteFilters}
                    onClick={() => {
                        setAllFieldsFilter('')
                        setCohortFilter(defaultFilter.cohortFilter)
                        setChallengeFilter(defaultFilter.challengeFilter)
                        challengColumn?.setFilterValue(null)
                        cohortColumn?.setFilterValue(null)
                    }}
                />

                <CSVDownloader
                    className={classnames([styles.headerButton, styles.headerExportButton])}
                    type={Type.Button}
                    filename={exportfilename}
                    bom={true}
                    data={table.getCoreRowModel().flatRows.map((i) => csvMapper(lang, i.original))}
                >
                    {lang.animationChallenge.filters.export}
                </CSVDownloader>
            </div>
        </div>
    )
}

const challengTypeToString = (lang: Lang, challengeType: BadgeType, challengeSize?: number) => {
    const sizeInfo = challengeType !== BadgeType.DIGITAL_CLEANUP_DAY ? `- ${challengeSize ?? ''} Mo` : ''
    return `${lang.goal.challenge.label(challengeType) ?? ''} ${sizeInfo}`
}

const csvMapper = (lang: Lang, data: ChallengeResult) => {
    return {
        'Utilisateur': `${data.firstName ?? ''} ${data.lastName ?? ''}`,
        'Email': data.email,
        'Groupe': data.cohortName,
        'Challenge': challengTypeToString(lang, data.challengeType, data.challengeSize),
        'Dates': periodTitle(data.startDate, data.endDate, lang),
        'Résultat': data.challengeResults.length ? data.challengeResults : 'Gagné',
    }
}

export default ChallengeResults
