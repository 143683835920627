import { Lang } from '../../../../models'
import defaultLang from '../../../lang/en'
import { tipsEn } from './tipsContent'

const LorealEn: Lang = {
    ...defaultLang,
    authentication: {
        ...defaultLang.authentication,
        title: "It's time to act",
        description: [
            "At L'Oréal, we are committed to reducing our environmental footprint across all aspects of our business, including our digital workplace.",
            'Greet by Lecko will help you to measure and understand your individual environmental footprint and to take actions towards more sustainable working habits.',
            ' ',
            'We all have a role to play !',
        ],
    },
    presentation: {
        title: "It's time to act",
        description: [
            "At L'Oréal, we are committed to reducing our environmental footprint across all aspects of our business, including our digital workplace.",
            'Greet by Lecko will help you to measure and understand your individual environmental footprint and to take actions towards more sustainable working habits.',
            ' ',
            'We all have a role to play !',
        ],
    },
    welcomePopupMessage: [
        "Welcome to Greet. As part of the Group's policies to reduce its environmental footprint, you can check your individual footprint and be invited to take part in challenges. Your professional email address and your Microsoft environment usage data will be used.",
        'Your data will be kept for 2 years and only you will have access to your indicators. Team indicators will also be developed on the basis of non-nominative aggregated data.',
        'You can delete your account on the platform at any time, and you have the rights set out in the Employee Data Protection Policy.'
    ],
    welcomePopup: {
        title: 'Informations',
        confirmBtn: 'I accept',
    },
    webinar: {
        ...defaultLang.webinar,
        title: 'Training',
        startAt: '',
        contentTitle: "We all have a role to play. Don't know how to start? Join a training session to learn how to reduce your digital footprint!",
        description: '',
        registerationUrl: 'https://qrco.de/bf4DPa',
        register: 'Read more',
    },
    legalInformations: {
        ...defaultLang.legalInformations,
        title: 'Legal information',
        paragraphes: [
            {
                title: 'Processing of personal data',
                content: `The GreeT application is hosted by Lecko.
                The analysis of activities on the Microsoft 365 environment is carried out on pseudonymized data aggregated through indicators in groups known as "teams". Only team members and the platform administrator can see these indicators.
                For its operation, it records the user's email address, first name, last name and avatar, transmitted by Microsoft at the time of connection. Only the user can see these indicators.
                The administrator can monitor the challenges, badges won and progress made by each user of the application. In order to improve the product, the history of connections to the application is also recorded.
                Users can ask to consult or delete this information by sending an email during the pilot period to :
                Arnaud Rayrole, Lecko, 64 rue des Archives 75003 Paris or by email to: arayrole@lecko.fr
                `,
            },
            {
                title: 'Data retention period',
                content: `User data is kept for the duration of their account activation and for a maximum of one month after deletion of their account. Each user can delete his or her account from the interface.
                In all cases, all application data will be deleted within 30 days of the end of the GreeT service.`,
            },
        ],
        cgu: {
            title: 'Terms and conditions of use',
            link: 'Download terms and conditions (pdf)',
        },
        charter: {
            title: 'Privacy policy',
            link: 'Download the privacy policy (pdf)',
        },
    },
    home: {
        ...defaultLang.home,
        blog: {
            ...defaultLang.home.blog,
            title: 'More Details',
        },
    },
    goal: {
        ...defaultLang.goal,
        tips: {
            title: 'Tips',
            readMore: 'More details',
        },
        individual: {
            ...defaultLang.goal.individual,
            deleteFolderProposal: {
                ...defaultLang.goal.individual.deleteFolderProposal,
                title: 'Clean up your Digital Workplace'
            }
        }
    },
    tipsContent: { ...tipsEn },
    welcomeBack: {
        ...defaultLang.welcomeBack,
        footerText: 'With Greet, every effort is made visible !',
    },
}

export default LorealEn
