import { createUseStyles } from 'react-jss'
import Logo from '../../assets/logos/CnafLogo.svg'
import SmallLogo from '../../assets/logos/CnafSmallLogo.svg'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'

const useStyles = createUseStyles({
    logo: {
        display: 'block',
        minHeight: '47px',
        height: '100%',
        width: '150% !important',
        maxWidth: '450px',
    },
    smallLogo: {
        display: 'none',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        logo: {
            display: 'none',
        },
        smallLogo: {
            display: 'block',
        },
    },
})

export const CnafLogo = () => {
    const styles = useStyles()
    return (
        <>
            <img className={styles.logo} src={Logo} />
            <img className={styles.smallLogo} src={SmallLogo} />
        </>
    )
}
