import { useEffect, useState } from 'react'
import { matchPath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { SingleValue } from 'react-select'

import { useInstance, useStore, useUser } from '../../contexts'
import { Params } from '../../models'
import { Cohort } from '../../models/Cohort'
import storage from '../../services/storage'
import { isAdminUsingAnimatorMode } from '../rolesUtils'
import useQueryParams from '../useQueryParams'
import { filterCohortsOnIds, filterCollectiveGoalsOnUserRole, matchingCohort } from '../utils'
import NewTabs from './NewTabs'
import useQVTAccess from '../../services/useQVTAccess'

interface Props {
    className: string
}

const useMatchParams = (path: string): any => {
    const { pathname } = useLocation()
    return matchPath(pathname, { path })?.params
}

enum GoalTabType {
    INDIVIDUAL = 'INDIVIDUAL',
    COLLECTIVE = 'COLLECTIVE',
    MANAGER = 'MANAGER',
    NOT_SET = '',
}

export default function Tabs({ className }: Readonly<Props>) {
    const { customer } = useInstance()
    const { user, lang } = useUser()
    const { state } = useStore()
    const { url, path } = useRouteMatch()
    const history = useHistory()
    const collectiveGoalParams = useMatchParams(`${path}/collective`)
    const individualGoalParams = useMatchParams(`${path}/individual`)
    const managerGoalParams = useMatchParams(`${path}/manager`)
    const queryParams = useQueryParams()
    const { langParam, customerParam } = useParams<Params>()
    const [selectedCohort, setSelectedCohort] = useState<Cohort>()
    const [cohorts, setCohorts] = useState<Cohort[]>([])

    const { userPayload, userCohorts } = state

    const selectedGoal =
        collectiveGoalParams != null ? GoalTabType.COLLECTIVE : individualGoalParams != null ? GoalTabType.INDIVIDUAL : managerGoalParams != null ? GoalTabType.MANAGER : null
    const goalId = collectiveGoalParams?.goalId ?? individualGoalParams?.goalId ?? managerGoalParams?.goalId
    const cohortName = decodeURIComponent(queryParams.get('cohortName') ?? '')
    const currentGoal = userPayload?.goals.find((goal) => goal?.egoal === goalId)
    const cohortsForGoal = filterCollectiveGoalsOnUserRole(currentGoal, user)
    const hasCollectiveGoal = cohortsForGoal != null && cohortsForGoal.length > 0
    const hasIndividualGoal = currentGoal?.individualGoal != null
    const hasGoalManagerAccess = customer.canViewOnlyQVTGoal && user?.isManager // and UserIsManager
    const qvtAccess = useQVTAccess()

    useEffect(() => {
        let goalUserCohorts = userPayload?.goals
            ?.filter((item) => item.egoal === goalId)
            .map((item) => (item?.collectiveGoals ?? []).map((val) => (userCohorts ?? []).find((item) => matchingCohort(item, val)) ?? val))
            .reduce((acc, val) => [...acc, ...val], [])
            .sort((a, b) => {
                if (a.cohortDisplayName < b.cohortDisplayName) {
                    return -1
                }
                if (a.cohortDisplayName > b.cohortDisplayName) {
                    return 1
                }
                return 0
            })

        if (isAdminUsingAnimatorMode(user)) {
            goalUserCohorts = filterCohortsOnIds(goalUserCohorts, user?.cohortIds)
        }

        const previousCohort = storage.cohortName.get()
        let currentCohort = goalUserCohorts?.find((item) => item.cohortId === (cohortName ?? previousCohort) || item.cohortName === (cohortName ?? previousCohort))

        const forbidenGoalAccess = (!hasCollectiveGoal && !hasIndividualGoal) || (collectiveGoalParams != null && currentCohort == null)
        const accessToCollectiveGoalOnly = hasCollectiveGoal && !hasIndividualGoal && individualGoalParams != null
        const accessToIndividualGoalOnly = !hasCollectiveGoal && hasIndividualGoal && collectiveGoalParams != null

        if (forbidenGoalAccess) {
            // Accès aux vues objectifs sans objectifs ou acces vue objectifs collectif avec une cohorte inconnue
            return history.replace(`/${langParam}/${customerParam}`)
        } else if (accessToCollectiveGoalOnly) {
            // on est sur la vue individuel sans d'objectif individuel et on a un objectif collectif
            return history.replace(`${url}/collective?cohortName=${encodeURIComponent(currentCohort?.cohortName ?? '')}`)
        } else if (accessToIndividualGoalOnly) {
            // on est sur la vue collective sans objectif collectif et on a un objectif individuel
            return history.replace(`${url}/individual`)
        }
        if (currentCohort == null) {
            currentCohort = goalUserCohorts?.length > 0 ? goalUserCohorts[0] : undefined
        }
        storage.cohortName.store(currentCohort?.cohortName ?? '')
        setSelectedCohort(currentCohort)
        setCohorts(goalUserCohorts)
    }, [goalId, state, userPayload])

    function selectCollectiveGoal() {
        if (hasCollectiveGoal && selectedGoal !== GoalTabType.COLLECTIVE) {
            history.push(`${url}/collective?cohortName=${encodeURIComponent(selectedCohort?.cohortName ?? '')}`)
        }
    }

    function selectIndividualGoal() {
        if (hasIndividualGoal && selectedGoal !== GoalTabType.INDIVIDUAL) {
            history.push(`${url}/individual`)
        }
    }

    function selectGoalManager() {
        if (hasGoalManagerAccess && selectedGoal !== GoalTabType.MANAGER) {
            history.push(`${url}/manager`)
        }
    }

    function selectCohort(newValue: SingleValue<{ value: Cohort | undefined; label: string | undefined }>) {
        const cohort = newValue?.value
        if (cohort?.cohortId !== selectedCohort?.cohortId && cohort?.cohortName !== selectedCohort?.cohortName) {
            setSelectedCohort(cohort)
            storage.cohortName.store(cohort?.cohortName ?? '')
            history.push(`${url}/collective?cohortName=${encodeURIComponent(cohort?.cohortName ?? '')}`)
        }
    }

    const atLeastOneCohortForThisGoal: boolean = cohorts?.length > 0
    return (
        <NewTabs
            className={className}
            groupTab={{
                active: selectedGoal === GoalTabType.COLLECTIVE,
                value: {
                    value: selectedCohort,
                    label: atLeastOneCohortForThisGoal ? selectedCohort!.cohortDisplayName : lang.components.select.selectCohortDefaultLabel,
                },
                options:
                    cohorts?.map((cohort) => ({
                        value: cohort,
                        label: cohort.cohortDisplayName,
                    })) || [],
                noOptionsMessage: lang.components.select.defaultNoChoicesLabel,
                isDisabled: selectedGoal !== GoalTabType.COLLECTIVE && atLeastOneCohortForThisGoal,
                onClick: selectCollectiveGoal,
                onChange: selectCohort,
            }}
            individualTab={{
                label: hasIndividualGoal ? lang.goal.individual.button.title : lang.goal.individual.button.addIndicators,
                active: selectedGoal === GoalTabType.INDIVIDUAL,
                hasIndicators: hasIndividualGoal,
                eGoal: goalId,
                url: `/${langParam}/${customerParam}/goal`,
                onClick() {
                    selectIndividualGoal()
                },
            }}
            managerTab={{
                label: lang.qvtManagerBoard.tabTitle,
                active: selectedGoal === GoalTabType.MANAGER,
                url: `/${langParam}/${customerParam}/goal`,
                hasGoalManagerAccess: hasGoalManagerAccess ?? false,
                eGoal: goalId,
                onClick: selectGoalManager,
            }}
            popupComponent={null}
            canSwitchGoal={qvtAccess}
        />
    )
}
