import { useInstance, useStore } from '../contexts'
import { EGoal } from '../models'

export default function useQVTAccess() {
    const {
        state: { userPayload },
    } = useStore()
    const { customer } = useInstance()

    return userPayload != null && !customer.canViewOnlyQVTGoal && customer.goals.includes(EGoal.QVT) && userPayload.qvtAccess
}
