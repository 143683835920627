import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { useInstance, useStore, useUser } from '../../contexts'
import { CoachingEvent } from '../../models/CoachingEvent'
import Lang from '../../models/Lang'
import ECoachingEvent from '../../models/coaching/ECoachingEvent'
import { usePut } from '../../services/api/useFetch'
import Popup from '../Popup'
import { toLiteralDateTimeString } from '../dateUtils'
import { isAdmin, isSuperAdmin, requireSuperAdmin } from '../rolesUtils'
import { GreetButton, GreetSubmitButton } from '../share/GreetButton'
import { DateTimePickerField, TextAreaField, TextField } from '../share/Inputs'
import CoachingItem from './CoachingItem'

const CoachingEvents = () => {
    const { customer } = useInstance()

    return {
        [ECoachingEvent.ANNOUNCEMENT]: <Announcement />,
        [ECoachingEvent.WEBINAR]: <Webinar />,
    }[customer.coachingEvent]
}

const Announcement = () => {
    const { customer } = useInstance()
    const { lang } = useUser()
    const currentEvent = buildDefaultCoachingEvent(lang)

    return (
        <CoachingItem
            title={lang.webinar.title}
            illustrationSrc={customer.webinarImg}
            contentTitle={currentEvent?.eventDate}
            content={currentEvent?.content}
            subContent={currentEvent?.description}
            buttonLabel={currentEvent.actionName}
            buttonLink={currentEvent.link}
        />
    )
}

const Webinar = () => {
    const { customer } = useInstance()
    const { user, lang } = useUser()
    const { state } = useStore()
    const [currentEvent, setCurrentEvent] = useState<CoachingEvent>({} as any)
    const [addToAgendaButton, setAddToAgendaButton] = useState<boolean>(false)
    const [editPopupVisible, setEditPopupVisible] = useState<boolean>(false)
    const { doPut: doAddToAgenda } = usePut({ endpoint: 'add-coaching-event-to-agenda' })

    useEffect(() => {
        if (!state?.coachingEvents) {
            return
        }
        const currentCoachingEvent = state.coachingEvents.find((item) => item.language === lang.code)
        setAddToAgendaButton(currentCoachingEvent != null)
        setCurrentEvent(buildDefaultCoachingEvent(lang, currentCoachingEvent))
    }, [lang.code, state?.coachingEvents])

    const openEditPop = () => setEditPopupVisible(true)
    const closeEditPop = () => setEditPopupVisible(false)
    const canEdit = isSuperAdmin(user) || (isAdmin(user) && customer.platformName === user?.platformName)

    return (
        <>
            <CoachingItem
                title={lang.webinar.title}
                onEditClick={canEdit ? openEditPop : null}
                illustrationSrc={customer.webinarImg}
                contentTitle={currentEvent?.eventDate}
                content={currentEvent?.content}
                subContent={currentEvent?.description}
                buttonLabel={currentEvent.actionName}
                buttonLink={currentEvent.link}
                extraButton={addToAgendaButton ? (classname: string) => <GreetButton type='primary' className={classname} label={lang.webinar.addToAgenda} onClick={doAddToAgenda} /> : undefined}
            />
            {editPopupVisible ? <EditCoachingEventsPopup onClose={closeEditPop} /> : null}
        </>
    )
}

const useEditCoachingEventsPpopupStyles = createUseStyles({
    editCoachingEventsPpopup: {
        display: 'flex',
        flexDirection: 'column',
        width: '125vh',
        backgroundColor: 'var(--gd-main-popup-background-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        padding: '30px',
        gap: '20px',
    },
    editCoachingEventsPpopupFormParts: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        justifyContent: 'center',
    },
    editCoachingEventsPpopupFormPart: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: '8px',
        justifyContent: 'center',
    },
    editCoachingEventsPpopupFormSubPart: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        borderLeft: '1px solid var(--gd-secondary-dark-color)',
        paddingLeft: '20px',
        gap: '10px',
    },
    langLabel: {
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 700,
        color: 'var(--gd-secondary-dark-color)',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
})

interface EditCoachingEventsPopup {
    onClose: () => void
}

interface EditCoachingEventsPopupForm {
    platformName: string
    eventDate: string
    contentFr: string
    descriptionFr: string
    // actionNameFr: string
    contentEng: string
    descriptionEng: string
    // actionNameEng: string
    link: string
}

const EditCoachingEventsPopup = ({ onClose }: EditCoachingEventsPopup) => {
    const { state, dispatch } = useStore()
    const { user, lang } = useUser()
    const { customer } = useInstance()
    const styles = useEditCoachingEventsPpopupStyles()
    const coachingEventFr = state?.coachingEvents?.find((item) => item.language === 'fr')
    const coachingEventEng = state?.coachingEvents?.find((item) => item.language === 'en')
    const { register, handleSubmit } = useForm<EditCoachingEventsPopupForm>({
        defaultValues: {
            platformName: customer.platformName,
            eventDate: coachingEventFr?.eventDate,
            contentFr: coachingEventFr?.content,
            descriptionFr: coachingEventFr?.description,
            // actionNameFr: coachingEventFr?.actionName,
            contentEng: coachingEventEng?.content,
            descriptionEng: coachingEventEng?.description,
            // actionNameEng: coachingEventEng?.actionName,
            link: coachingEventFr?.link,
        },
    })

    const { doPut: doUpdate } = usePut({
        endpoint: 'coaching-events',
        onSuccess: (data) => {
            dispatch({ type: 'coaching-events-fetched', value: data })
            onClose()
        },
    })

    const onSubmit: SubmitHandler<EditCoachingEventsPopupForm> = (data: EditCoachingEventsPopupForm) => doUpdate(data)

    const canEdit = isSuperAdmin(user) || (isAdmin(user) && customer.platformName === user?.platformName)

    return canEdit ? (
        <Popup onCloseRequest={onClose} closeOnOutside={false}>
            <form className={styles.editCoachingEventsPpopup} onSubmit={handleSubmit(onSubmit)}>
                <span className={styles.langLabel}>{lang.webinar.title}</span>
                {requireSuperAdmin(user, <TextField type='text' label='Client' name='platformName' register={register} />)}
                <DateTimePickerField label={lang.webinar.form.eventDate} name='eventDate' required={true} register={register} style={{ padding: '10px' }} />
                <div className={styles.editCoachingEventsPpopupFormParts}>
                    <div className={styles.editCoachingEventsPpopupFormPart}>
                        <span className={styles.langLabel}>Français</span>
                        <div className={styles.editCoachingEventsPpopupFormSubPart}>
                            <TextAreaField label={lang.webinar.form.content} name='contentFr' required={true} register={register} type='text' />
                            <TextAreaField label={lang.webinar.form.description} name='descriptionFr' required={true} register={register} />
                        </div>
                    </div>
                    <div className={styles.editCoachingEventsPpopupFormPart}>
                        <span className={styles.langLabel}>Anglais</span>
                        <div className={styles.editCoachingEventsPpopupFormSubPart}>
                            <TextAreaField label={lang.webinar.form.content} name='contentEng' required={true} register={register} type='text' />
                            <TextAreaField label={lang.webinar.form.description} name='descriptionEng' required={true} register={register} />
                        </div>
                    </div>
                </div>
                <TextField label={lang.webinar.form.registerationUrl} name='link' required={true} register={register} type='text' />
                <div className={styles.footer}>
                    <GreetButton type='secondary-inv' onClick={onClose} label={lang.webinar.form.cancel} />
                    <GreetSubmitButton type='secondary' label={lang.webinar.form.save} />
                </div>
            </form>
        </Popup>
    ) : null
}

const buildDefaultCoachingEvent = (lang: Lang, currentCoachingEvent?: CoachingEvent) => {
    return {
        language: currentCoachingEvent?.language ?? lang.code,
        eventDate: currentCoachingEvent?.eventDate ? toLiteralDateTimeString(currentCoachingEvent?.eventDate, lang.locale) : lang.webinar.startAt,
        content: currentCoachingEvent?.content ?? lang.webinar.contentTitle,
        description: currentCoachingEvent?.description ?? lang.webinar.description,
        link: currentCoachingEvent?.link ?? lang.webinar.registerationUrl,
        actionName: lang.webinar.register,
    }
}

export default CoachingEvents
