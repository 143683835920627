import { createUseStyles } from 'react-jss'
import IconAssets from '../assets/icon/IconAssets'
import { useInstance, useUser } from '../contexts'
import Popup from './Popup'

const useStyles = createUseStyles({
    accessDeniedPopup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '70px 0px',
        rowGap: '15px',
        backgroundColor: 'var(--gd-main-popup-background-color)',
    },
    iconContainer: {
        width: '68px',
        height: '68px',
    },
    title: {
        fontSize: 'var(--gd-size-highlighted-title)',
        color: 'var(--gd-secondary-color)',
        marginBottom: '15px',
    },
    message: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '70%',
        color: 'var(--gd-text-dark-color)',
        fontSize: 'var(--gd-size-body-text)',
        textAlign: 'center',
    },
    messagePart: {
        userSelect: 'text',
    },
    link: {
        color: 'var(--gd-secondary-color)',
        fontWeight: 'bold'
    }
})

const AccessDeniedPopup = () => {
    const { customer } = useInstance()
    const { lang } = useUser()
    const styles = useStyles()

    return (
        <Popup
            closable={false}
            onCloseRequest={() => {
                return
            }}
        >
            <div className={styles.accessDeniedPopup}>
                <div className={styles.iconContainer}>
                    <IconAssets.AccessDeniedIcon />
                </div>
                <div className={styles.message}>
                    <span className={styles.title}>{lang.thanksForInterest}</span>
                    {customer.contact.hasLinkSupport ? (
                        <span className={styles.messagePart}>{lang.accessDeniedPopupMessageWithLink}
                            <a href={customer.contact.link} target='_blank' rel='noreferrer' className={styles.link}>
                                {lang.linkName}
                            </a>.
                        </span>
                    ) : (
                        <span className={styles.messagePart}>{lang.accessDeniedPopupMessage(`${customer.contact.support.name} (${customer.contact.support.mail})`)}</span>
                    )}
                </div>
            </div>
        </Popup>
    )
}

export default AccessDeniedPopup
