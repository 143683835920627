import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { GreetButton } from '../share/GreetButton'

const useCoachingItemStyles = createUseStyles({
    coachingItem: {
        display: 'flex',
        width: '100%',
        maxWidth: 'calc(100% / 3)',
        flexDirection: 'column',
        alignItems: 'center',
        height: '580px',
        backgroundColor: 'var(--gd-blogs-primary-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        color: 'var(--gd-text-clear-color)',
        textAlign: 'center',
    },
    coachingItemTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        gap: '10px',
        backgroundColor: 'var(--gd-blogs-primary-dark-color)',
        color: 'var(--gd-blogs-primary-dark-text-color)',
        borderRadius: 'var(--gd-border-normal-radius) var(--gd-border-normal-radius) 0 0',
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
        padding: '10px 0',
        width: '100%',
    },
    coachingItemTitleAction: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        cursor: 'pointer',
    },
    coachingItemContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '554px',
        maxHeight: '454px',
    },
    coachingItemIllustrationContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '225px',
        maxHeight: '225px',
        minWidth: '360px',
        backgroundPosition: 'center center',
    },
    coachingItemICompllustration: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        paddingBottom: '10px',
        height: '130px',
    },
    coachingItemTextContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 25px',
        gap: '10px',
        height: '250px',
        maxHeight: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'var(--gd-blogs-primary-dark-text-color)',
    },
    coachingItemContentTitle: {
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
    },
    coachingItemContent: {
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
    },
    coachingItemSubContent: {
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'normal',
    },
    coachingItemActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
    },
    coachingItemButton: {
        backgroundColor: 'var(--gd-blogs-secondary-color)',
        color: 'var(--gd-blogs-secondary-text-color)',
    },
})
interface CoachingItemProps {
    title: string
    onEditClick?: (() => void) | null
    illustrationSrc?: string
    illustrationImg?: any
    contentTitle?: string
    content?: string
    subContent?: string
    buttonLabel: string
    buttonLink: string
    extraButton?: (classname: string) => React.ReactNode
}
const CoachingItem = ({ title, onEditClick, illustrationSrc, illustrationImg, contentTitle, content, subContent, buttonLabel, buttonLink, extraButton }: CoachingItemProps) => {
    const styles = useCoachingItemStyles()
    return (
        <div className={styles.coachingItem}>
            <div className={styles.coachingItemTitle}>
                <div>{title}</div>
                {onEditClick != null ? (
                    <div className={styles.coachingItemTitleAction} onClick={onEditClick}>
                        <IconAssets.Pen fillColor='var(--gd-blogs-primary-dark-text-color)' />
                    </div>
                ) : null}
            </div>
            <div className={styles.coachingItemContentContainer}>
                {illustrationSrc != null ? <div className={styles.coachingItemIllustrationContainer} style={{ backgroundImage: `url(${illustrationSrc})` }}></div> : null}
                {illustrationImg != null ? (
                    <div className={classnames([styles.coachingItemIllustrationContainer, styles.coachingItemICompllustration])}>{illustrationImg}</div>
                ) : null}
                <div className={styles.coachingItemTextContentContainer}>
                    <div className={styles.coachingItemContentTitle}>{contentTitle}</div>
                    <div className={styles.coachingItemContent}>{content}</div>
                    <div className={styles.coachingItemSubContent}>{subContent}</div>
                </div>
            </div>
            {buttonLink != '#' ? (
                <div className={styles.coachingItemActions}>
                    <GreetButton
                        type='secondary'
                        label={buttonLabel}
                        className={styles.coachingItemButton}
                        onClick={() => (buttonLink ? window.open(buttonLink, '_blank', 'noopener,noreferrer') : null)}
                    />
                    {extraButton?.(styles.coachingItemButton)}
                </div>
            ) : null}
        </div>
    )
}

export default CoachingItem
