import classNames from 'classnames'
import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import IconAssets from '../assets/icon/IconAssets'
import { useStore, useUser } from '../contexts'
import { Lang } from '../models'
import { Badge, BadgeType } from '../models/Badge'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import Popup from './Popup'
import Title from './Title'
import { periodTitle, substractYears, toYearString } from './dateUtils'
import { GreetButton } from './share/GreetButton'
import GreetFeaturingComponent, { GreetFeaturingTarget } from './share/GreetFeaturingComponent'
import { getCohortName, getPeriod } from './utils'

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '10px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: '20px',
        padding: '20px',
        borderRadius: 'var(--gd-border-normal-radius)',
        backgroundColor: 'var(--gd-background-clear-color)',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '20px',
        height: '440px',
        overflow: 'hidden',
        transition: 'height 0.5s ease',
    },
    badgeContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '275px',
        height: '400px',
        backgroundColor: 'var(--gd-main-app-background-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        alignItems: 'center',
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingLeft: '30px',
        paddingRight: '30px',
        justifyContent: 'space-between',
    },
    badgeUpContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        alignItems: 'center',
    },
    badgeTitle: {
        padding: '5px',
        backgroundColor: 'var(--gd-background-clear-color)',
        color: 'var(--gd-secondary-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        fontWeight: '700',
        textAlign: 'center',
    },
    result: {
        fontWeight: '700',
        color: 'var(--gd-text-dark-color)',
    },
    icon: {
        height: '90px',
    },
    badgeDescription: {
        textAlign: 'center',
        color: 'var(--gd-text-dark-color)',
    },
    button: {
        justifySelf: 'flex-end',
    },
    lastBadgeContainer: {
        marginRight: 0,
    },
    expandButtonContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },
    expandButton: {},
    contentContainerExpanded: {
        height: 'calc(400px * 3 + 80px)',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        container: {
            padding: '20px',
            paddingRight: 0,
        },
        contentContainer: {
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            padding: '0',
            overflowX: 'scroll',
            borderRadius: 0,
            height: '400px',
        },
        badgeContainer: {
            flexBasis: '250px',
            flexGrow: 0,
            flexShrink: 0,
        },
        lastBadgeContainer: {
            marginRight: '20px',
        },
        expandButtonContainer: {
            display: 'none',
        },
    },
})

const getIcon = (badgeType: BadgeType) => {
    switch (badgeType) {
        case BadgeType.DRIVE:
            return <IconAssets.Garbage />
        case BadgeType.MAIL:
            return <IconAssets.Attachement />
        case BadgeType.PLANET_FRIENDLY:
            return <IconAssets.PlanetFriendlyIcon />
        case BadgeType.INTERN_ATTACHMENT:
            return <IconAssets.InternAttachment />
        case BadgeType.DIGITAL_CLEANUP_DAY:
            return <IconAssets.Cleanup />
        case BadgeType.MAILSHOT:
            return <IconAssets.MailShot />
        case BadgeType.IMAGE_HUNT:
            return <IconAssets.ImageHunt />
        case BadgeType.VIDEO_HUNT:
            return <IconAssets.VideoHunt />
        default:
            return null
    }
}

const BadgeMonitorRanking = () => {
    const { state } = useStore()
    const { lang } = useUser()
    const styles = useStyles()
    const badges = state?.userPayload?.userBadges ?? []
    const [currentBadges, setCurrentBadges] = useState<Badge[] | null>(null)
    const [currentBadgeType, setCurrentBadgeType] = useState<BadgeType | null>(null)
    const [popupIsOpen, displayPopup] = useState<boolean>(false)
    const [containerIsExpanded, setContainerIsExpanded] = useState<boolean>(false)

    const onButtonClick = (badgeType: BadgeType) => {
        setCurrentBadgeType(badgeType)
        setCurrentBadges(badges.filter((badge) => badge.type === badgeType))
        displayPopup(true)
    }

    return (
        <div className={styles.container}>
            <div>
                <Title title={lang.badgeMonitor.title} />
            </div>
            <div className={classNames(containerIsExpanded ? [styles.contentContainer, styles.contentContainerExpanded] : [styles.contentContainer])}>
                {Object.values(BadgeType).map((badgeType, index) => {
                    const nbBadge = badges.filter((badge) => badge.type === badgeType).length
                    const result = nbBadge >= 2 ? `${nbBadge} ${lang.badgeMonitor.resultForServeralBadges}` : `${nbBadge} ${lang.badgeMonitor.resultForOneBadge}`
                    const containerStyles = [styles.badgeContainer]
                    if (index === Object.values(BadgeType).length - 1) {
                        containerStyles.push(styles.lastBadgeContainer)
                    }
                    return (
                        <div key={badgeType} className={classNames(containerStyles)}>
                            <div className={styles.badgeUpContainer}>
                                <div className={styles.badgeTitle}>{lang.goal.challenge.label(badgeType)}</div>
                                <div className={styles.result}>{result}</div>
                                <div className={styles.icon}>{getIcon(badgeType)}</div>
                                <div className={styles.badgeDescription}>{lang.goal.challenge.badgeDescription(badgeType)}</div>
                            </div>
                            <GreetButton className={styles.button} label={lang.badgeMonitor.seeAll} disabled={nbBadge === 0} onClick={() => onButtonClick(badgeType)} />
                        </div>
                    )
                })}
            </div>
            <div className={styles.expandButtonContainer}>
                <GreetButton
                    type='secondary-inv'
                    className={styles.expandButton}
                    label={containerIsExpanded ? lang.badgeMonitor.seeLess : lang.badgeMonitor.seeMore}
                    icon={containerIsExpanded ? <IconAssets.Minus /> : <IconAssets.PlusLightIcon />}
                    onClick={() => setContainerIsExpanded(!containerIsExpanded)}
                />
            </div>
            {popupIsOpen && currentBadges != null && currentBadgeType != null ? (
                <BadgeMonitorPopup onClose={() => displayPopup(false)} badges={currentBadges} badgeType={currentBadgeType} lang={lang} />
            ) : null}
        </div>
    )
}

interface BadgeMonitorPopupProps {
    onClose: () => void
    badges: Badge[]
    badgeType: BadgeType
    lang: Lang
}

const usePopupStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100vh',
        maxHeight: '90vh',
        backgroundColor: 'var(--gd-primary-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
    },
    title: {
        paddingTop: '20px',
        paddingBottom: '20px',
        color: 'var(--gd-main-popup-background-color)',
        textAlign: 'center',
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: '700',
    },
    badgesContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'var(--gd-main-popup-background-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '20px',
        paddingBottom: '20px',
        gap: '10px',
        overflowY: 'scroll',
    },
    badge: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '75px',
        alignItems: 'center',
    },
    icon: {
        height: '100%',
        zIndex: '1',
    },
    descriptionContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: 'calc(100% + 10px)',
        height: '60%',
        alignItems: 'center',
        fontSize: 'var(--gd-size-small-body-text)',
        position: 'relative',
        left: '-10px',
    },
    descriptionContainerNoGroup: {},
    description: {
        display: 'flex',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        backgroundColor: 'var(--gd-background-clear-color)',
        paddingLeft: '30px',
    },
    descriptionNoGroup: {
        width: '90%',
        borderTopRightRadius: 'var(--gd-border-small-radius)',
        borderBottomRightRadius: 'var(--gd-border-small-radius)',
    },
    descriptionBold: {
        fontWeight: '700',
    },
    group: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30%',
        height: '100%',
        fontWeight: '700',
        backgroundColor: 'var(--gd-background-dark-color)',
        borderTopRightRadius: 'var(--gd-border-small-radius)',
        borderBottomRightRadius: 'var(--gd-border-small-radius)',
    },
    noBadge: {
        textAlign: 'center',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        container: {
            borderRadius: 0,
            maxHeight: '100vh',
        },
        badgesContainer: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            height: '100%',
            overflowY: 'scroll',
            gap: 0,
            paddingLeft: 0,
            paddingRight: 0,
        },
        badge: {
            height: '125px',
            paddingLeft: '30px',
        },
        descriptionContainer: {
            flexDirection: 'column-reverse',
            left: '-30px',
        },
        descriptionContainerNoGroup: {
            flexDirection: 'column',
            justifyContent: 'center',
        },
        description: {
            width: '100%',
            height: '70%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            paddingLeft: '30px',
            justifyContent: 'center',
            borderBottomRightRadius: 'var(--gd-border-small-radius)',
        },
        descriptionNoGroup: {
            height: '80%',
        },
        group: {
            width: '100%',
            height: '30%',
            paddingLeft: '30px',
            borderBottomRightRadius: 0,
            borderTopRightRadius: 'var(--gd-border-small-radius)',
        },
    },
})

const BadgeMonitorPopup = ({ onClose, badges, badgeType }: BadgeMonitorPopupProps) => {
    const { lang } = useUser()
    const { state } = useStore()
    const styles = usePopupStyles()

    const getBadgeDescription = (badge: Badge) => {
        switch (badge.type) {
            case BadgeType.DRIVE:
                return lang.badgeMonitor.popup.drive(Math.round(badge.reducedBy))
            case BadgeType.MAIL:
                return lang.badgeMonitor.popup.mail(Math.round(badge.reducedBy))
            case BadgeType.PLANET_FRIENDLY:
                return lang.badgeMonitor.popup.planetFriendly(Math.round(badge.reducedBy), toYearString(substractYears(badge.period, 1)))
            case BadgeType.INTERN_ATTACHMENT:
                return lang.badgeMonitor.popup.internAttachment
            case BadgeType.DIGITAL_CLEANUP_DAY:
                return lang.badgeMonitor.popup.digitalCleanupDay(Math.round(badge.reducedBy))
            case BadgeType.MAILSHOT:
                return lang.badgeMonitor.popup.mailShot(Math.round(badge.reducedBy))
            case BadgeType.IMAGE_HUNT:
                return lang.badgeMonitor.popup.imageHunt(Math.round(badge.reducedBy))
            case BadgeType.VIDEO_HUNT:
                return lang.badgeMonitor.popup.videoHunt(Math.round(badge.reducedBy))
            default:
                return null
        }
    }

    return (
        <Popup onCloseRequest={onClose} closeOnOutside={false} closeIconColor='var(--gd-main-popup-background-color)'>
            <div className={styles.container}>
                <div className={styles.title}>{lang.goal.challenge.label(badgeType)}</div>
                <div className={styles.badgesContainer}>
                    {badges.length > 0 ? (
                        <>
                            {badges.map((badge) => {
                                const cohortName = getCohortName(badge, state.cohortsPayload, state.userCohorts)
                                const { from, to } = getPeriod(badge)
                                return (
                                    <div key={`${badge.type}-${cohortName}-${from}`} className={styles.badge}>
                                        <div className={styles.icon}>{getIcon(badge.type)}</div>
                                        <div className={classNames(styles.descriptionContainer, !cohortName ? styles.descriptionContainerNoGroup : '')}>
                                            <div className={classNames(styles.description, !cohortName ? styles.descriptionNoGroup : '')}>
                                                {getBadgeDescription(badge)}&nbsp;
                                                <span className={styles.descriptionBold}>
                                                    {periodTitle(from, to, lang)}
                                                </span>
                                            </div>
                                            {!cohortName ? null : <div className={styles.group}>{cohortName}</div>}
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    ) : (
                        <div className={styles.noBadge}>{lang.badgeMonitor.noBadge}</div>
                    )}
                </div>
            </div>
        </Popup>
    )
}

const BadgeMonitorLegacy = () => {
    return <></>
}

const BadgeMonitor = () => {
    return <GreetFeaturingComponent nextDef={BadgeMonitorRanking} legacyDef={BadgeMonitorLegacy} target={GreetFeaturingTarget.RANKING} />
}

export default BadgeMonitor
