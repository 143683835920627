import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { useUser } from '../../contexts'
import UserPayload from '../../models/UserPayload'
import payload from '../../services/storage/payload'
import InfoBulle from '../share/Infobulle'
import { convertToRem } from '../utils'
import QVTBulbInformation from './QVTBulbInformation'

const useMeetingAttendeesDistributionStyles = createUseStyles({
    container: {
        display: 'flex',
        maxHeight: convertToRem(500),
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
        backgroundColor: 'var(--gd-main-app-background-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        padding: '20px',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        alignContent: 'center',
        width: '100%',
        gap: '8px',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 'bold',
    },
    graphContent: {
        height: '100%',
    },
    graphTop: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100% - 20px)',
    },
    graphY: {
        width: '20px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginRight: '8px',
        '& span': {
            display: 'flex',
            position: 'relative',
            justifyContent: 'flex-end',
            fontSize: '14px',
        },
    },
    graphWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        alignItems: 'flex-end',
        padding: '0 5px',
        borderBottom: '1px solid var(--gd-background-dark-color)',
    },
    graphX: {
        height: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '14px',
        justifyContent: 'space-between',
        marginTop: '5px',
    },
    descriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        backgroundColor: 'var(--gd-background-clear-color)',
        borderRadius: '10px',
        padding: '10px 20px',
        fontSize: '14px',
    },
    descriptionTitle: {
        fontWeight: 'bold',
    },
    legendContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
        fontSize: '14px',
        paddingLeft: '20px',
        marginBottom: '10px',
    },
    circle: {
        borderRadius: '50%',
        paddingLeft: '10px',
        height: '10px',
        backgroundColor: 'var(--gd-primary-color)',
    },
    slot: {
        width: '100%',
        borderTopLeftRadius: '2px',
        borderTopRightRadius: '2px',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:hover $tooltip': {
            visibility: 'visible',
        },
    },
    tooltip: {
        position: 'absolute',
        visibility: 'hidden',
        transform: 'translateY(-100%)',
        zIndex: 10,
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    infobullIcon: {
        width: '20px',
        padding: '3px 0',
    },
    tooltipRectangle: {
        padding: `${convertToRem(4)} ${convertToRem(7)}`,
        maxWidth: convertToRem(170),
        maxHeight: convertToRem(58),
        minWidth: convertToRem(124),
        minHeight: convertToRem(46),
        backgroundColor: 'var(--gd-background-dark-color)',
        borderRadius: convertToRem(5),
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'normal',
        color: 'var(--gd-text-dark-color)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tooltipTriangle: { position: 'relative', top: convertToRem(-2) },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        alignItems: 'flex-end',
        padding: '0 5px',
        borderBottom: '1px solid var(--gd-background-dark-color)',
    },
})
const NB_MAX_MEETING = 30
interface QVTMeetingAttendeesDistributionProps {
    userPayload: UserPayload
}

export default function QVTMeetingAttendeesDistribution({ userPayload }: Readonly<QVTMeetingAttendeesDistributionProps>) {
    const { lang } = useUser()
    const styles = useMeetingAttendeesDistributionStyles()
    const xValues: number[] = [2, 3, 4, 5, 6, 7, 8, 9, 10]
    const slots = useMemo(() => userPayload?.qvtDistributionParticipantsMeetings?.qvtParticipantsMeetings ?? [], [payload])
    const nbMaxMeetings = useMemo(() => {
        const maxNbMeetings = Math.max(...slots.map((item) => item.nbMeetings))
        return maxNbMeetings > 0 && maxNbMeetings <= NB_MAX_MEETING ? maxNbMeetings : NB_MAX_MEETING
    }, [slots])

    const graphYAxesSteps = useMemo(() => {
        const isDividerOf5 = nbMaxMeetings % 5 === 0
        const maxStep = isDividerOf5 ? nbMaxMeetings + 5 : nbMaxMeetings + (5 - (nbMaxMeetings % 5))
        return Array.from({ length: maxStep / 5 + 1 }, (_, i) => i * 5).reverse()
    }, [nbMaxMeetings])

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div>{lang.goal.qvt.meetingAttendeesDistribution.title}</div>
                <InfoBulle translateX='-105%' translateY='0%' text={lang.goal.qvt.meetingAttendeesDistribution.legend}>
                    <div className={styles.infobullIcon}>
                        <IconAssets.Information />
                    </div>
                </InfoBulle>
            </div>

            <div className={styles.graphContent}>
                <div className={styles.graphTop}>
                    <div className={styles.graphY}>
                        {graphYAxesSteps.map((step) => (
                            <span key={step}>{step}</span>
                        ))}
                    </div>
                    <div className={styles.graphWrapper}>
                        <div className={styles.content}>
                            {xValues.map((x) => {
                                let nbMeetings = slots.find((slot) => x == slot.attendees)?.nbMeetings
                                if (x === 10) {
                                    nbMeetings = slots
                                        .filter((slot) => slot.attendees > x)
                                        .map((v) => v.nbMeetings)
                                        .reduce((acc, curr) => acc + curr, 0)
                                }
                                const maxStep = Math.max(...graphYAxesSteps)
                                const barHeight = nbMeetings == null ? 0 : (100 / (maxStep + 1)) * (nbMeetings ?? 1)
                                return (
                                    <div
                                        key={x}
                                        className={styles.slot}
                                        style={{
                                            height: `${barHeight}%`,
                                            backgroundColor: 'var(--gd-primary-color)',
                                        }}
                                    >
                                        <div className={styles.tooltip}>
                                            <div className={styles.tooltipRectangle}>{lang.goal.qvt.meetingAttendeesDistribution.chartBarTooltip(nbMeetings ?? 0, x)}</div>
                                            <svg className={styles.tooltipTriangle} width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                <path d='M10 12L0.473721 0.75L19.5263 0.749998L10 12Z' fill='var(--gd-background-dark-color)' />
                                            </svg>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className={styles.graphX} style={{ paddingLeft: '45px', paddingRight: '10px' }}>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                    <span>6</span>
                    <span>7</span>
                    <span>8</span>
                    <span>9</span>
                    <span>10+</span>
                </div>
            </div>
            <QVTBulbInformation textContent={lang.goal.qvt.meetingAttendeesDistribution.information} />
        </div>
    )
}
