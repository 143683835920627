/**
 * define date functions used in greet-desktop
 * that wrap the used date library functions
 * to have less effort in case the library changes
 *
 * Note: in case the date library changes, check if it provides an adapter for chartjs
 */

import { DateTime, DurationLike, DurationLikeObject, DurationUnits } from 'luxon'
import { Lang } from '../models'
import { WorkingDaySettings } from '../models/WorkingDaySettings'

export enum TimeUnit {
    YEARS = 'years',
    MONTHS = 'months',
    DAYS = 'days',
    HOURS = 'hours',
    WEEKS = 'weeks',
}

// ************ mappers to luxon time units *********************************
const timeUnitToLuxonDuration: Record<TimeUnit, (amount: number) => DurationLike> = {
    hours: function (amount) {
        return { 'hours': amount }
    },
    days: function (amount) {
        return { 'days': amount }
    },
    weeks: function (amount) {
        return { 'weeks': amount }
    },
    months: function (amount) {
        return { 'months': amount }
    },
    years: function (amount) {
        return { 'years': amount }
    },
}

const timeUnitToLuxonDurationUnits: Record<TimeUnit, DurationUnits> = {
    hours: 'hours',
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    years: 'years',
}

const timeUnitToKeyofLuxonDurationLikeObject: Record<TimeUnit, keyof DurationLikeObject> = {
    hours: 'hours',
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    years: 'years',
}
// ***********************************************************

export function substract(dateInput: string | Date, amount: number, timeUnit: TimeUnit): Date {
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput
    if (isNaN(date.getDate())) {
        throw new Error('Parsing dates errors :' + dateInput)
    }

    const clone = DateTime.fromJSDate(date)
    return clone.minus(timeUnitToLuxonDuration[timeUnit](amount)).toJSDate()
}

export function substractYears(date: string | Date, amount: number): Date {
    return substract(date, amount, TimeUnit.YEARS)
}

export function substractMonths(date: string | Date, amount: number): Date {
    return substract(date, amount, TimeUnit.MONTHS)
}

export function substractDays(date: string | Date, amount: number): Date {
    return substract(date, amount, TimeUnit.DAYS)
}

// Keep this private, dont export it
const valueOfJsDate = (date: Date, withTime: boolean): DateTime => {
    return withTime ? DateTime.fromJSDate(date) : DateTime.fromJSDate(date).startOf('day')
}

export function add(dateInput: string | Date, amount: number, timeUnit: TimeUnit): Date {
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput
    if (isNaN(date.getDate())) {
        throw new Error('Parsing dates errors :' + dateInput)
    }

    const clone = DateTime.fromJSDate(date)
    return clone.plus(timeUnitToLuxonDuration[timeUnit](amount)).toJSDate()
}

export function addDays(date: string | Date, amount: number): Date {
    return add(date, amount, TimeUnit.DAYS)
}

export function diff(date: Date, other: Date, timeUnit: TimeUnit, time = false): number {
    return valueOfJsDate(date, time).diff(valueOfJsDate(other, time), timeUnitToLuxonDurationUnits[timeUnit]).as(timeUnitToKeyofLuxonDurationLikeObject[timeUnit])
}

export function areSame(date: Date, other: Date, time = false): boolean {
    return valueOfJsDate(date, time) //
        .equals(valueOfJsDate(other, time))
}

export function isAfter(date: Date, other: Date, time = false): boolean {
    return valueOfJsDate(date, time) > valueOfJsDate(other, time)
}

export function isBefore(date: Date, other: Date, time = false): boolean {
    return valueOfJsDate(date, time) < valueOfJsDate(other, time)
}

export function dateToISOStringWithoutTime(date: Date): string {
    const thisDate = new Date(date)
    return thisDate.toISOString().split('T')[0]
}

// Keep this private, dont export it
const toLocaleString = (dateInput: string | Date, locale: string, format: any): string => {
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput
    if (isNaN(date.getDate())) {
        throw new Error('Parsing dates errors :' + dateInput)
    }

    return date.toLocaleString(locale, format)
}

// Keep this private, dont export it
const toLocaleTimeString = (dateInput: string | Date, locale: string, format: any): string => {
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput
    if (isNaN(date.getDate())) {
        throw new Error('Parsing dates errors :' + dateInput)
    }

    return date.toLocaleTimeString(locale, format)
}

export const toYearString = (date: Date | string) => {
    // Use default locale code as we just want to display a four digit number representing the year
    return date.toLocaleString('fr', { year: 'numeric' })
}

export const toLastYearString = (date: Date) => {
    date.setFullYear(date.getFullYear() - 1)
    // Use default locale code as we just want to display a four digit number representing the year
    return date.toLocaleString('fr', { year: 'numeric' })
}

export const periodTitle = (from: Date, to: Date, lang: Lang) => {
    const [formattedFrom, formattedTo] = toLiteralIntervalParts(from, to, lang)
    return lang.date.range(formattedFrom, formattedTo)
}

export const toDayLiteralMonthString = (date: Date | string, locale = 'fr') => {
    return toLocaleString(date, locale, {
        month: 'long',
        day: 'numeric',
    })
}

export const toLiteralDayLiteralMonthString = (date: Date | string, locale = 'fr') => {
    return toLocaleString(date, locale, {
        month: 'long',
        day: 'numeric',
        weekday: 'long',
    })
}

export const toDayMonthString = (date: Date | string, locale = 'fr') => {
    return toLocaleString(date, locale, {
        month: 'numeric',
        day: 'numeric',
    })
}

export const daySuffixDecorator = (day: string, locale = 'fr') => {
    if(day === '1'){
        switch (locale){
            case 'fr':
                return '1er'
            case 'en':
                return '1st'
            default:
                console.error('Day suffix decorator isn\'t set up for this locale')
                return day
        }
    } else {
        return day
    }
}

export const toDayNumericString = (date: Date | string, lang: Lang) => {
    const dateFormatted = toLocaleString(date, lang.locale, {
        day: 'numeric',
    })
    return daySuffixDecorator(dateFormatted)
}

export const toLiteralDayNumericString = (date: Date | string, locale = 'fr') => {
    return toLocaleString(date, locale, {
        day: 'numeric',
        weekday: 'long',
    })
}

export const toNumericString = (date: Date | string, locale = 'fr') => {
    return toLocaleString(date, locale, {
        year: 'numeric',
        month: 'numeric',
    })
}

export const toLiteralMonthString = (date: Date | string, locale = 'fr') => {
    return toLocaleString(date, locale, {
        month: 'long',
    })
}

export const toLiteralMonthYearString = (date: Date | string, locale = 'fr') => {
    return toLocaleString(date, locale, {
        year: 'numeric',
        month: 'long',
    })
}

export const toLiteralDateTimeString = (date: Date | string, locale = 'fr') => {
    return toLocaleTimeString(date, locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    })
}

export const toLiteralDateString = (date: Date | string, locale = 'fr') => {
    return toLocaleString(date, locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })
}

export const areOnSameWeek = (date: Date, other: Date, time = false): boolean => {
    return valueOfJsDate(date, time).year === valueOfJsDate(other, time).year && valueOfJsDate(date, time).weekNumber === valueOfJsDate(other, time).weekNumber
}

export const areOnSameDay = (date: Date, other: Date, time = false): boolean => {
    return (
        valueOfJsDate(date, time).year === valueOfJsDate(other, time).year &&
        valueOfJsDate(date, time).weekNumber === valueOfJsDate(other, time).weekNumber &&
        valueOfJsDate(date, time).day === valueOfJsDate(other, time).day
    )
}

export const isOnLastWeek = (date: Date, time = false): boolean => {
    const now = valueOfJsDate(new Date(), time)
    const clone = valueOfJsDate(date, time)

    if (now.year === clone.year) {
        return now.weekNumber - clone.weekNumber === 1
    }

    const numberOfWeeksLastYear = valueOfJsDate(substractYears(now.toJSDate(), 1), time).weeksInWeekYear
    return now.weekNumber - clone.weekNumber + numberOfWeeksLastYear === 1
}

export const isOnCurrentWeek = (date: Date, time = false): boolean => {
    const now = valueOfJsDate(new Date(), time)
    const clone = valueOfJsDate(date, time)

    if (now.year !== clone.year) {
        return false
    }

    return now.weekNumber === clone.weekNumber
}

export const daysInMonth = (date: Date): number => {
    return valueOfJsDate(date, false).daysInMonth
}

export const toLiteralIntervalParts = (startDate: Date, endDate: Date, lang: Lang) => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    if (toLiteralMonthYearString(start, lang.locale) === toLiteralMonthYearString(end, lang.locale)) {
        return [toDayNumericString(start, lang), toDayLiteralMonthString(end, lang.locale)]
    } else {
        return [toDayLiteralMonthString(start, lang.locale), toDayLiteralMonthString(end, lang.locale)]
    }
}

export const toLiteralDayIntervalParts = (startDate: Date, endDate: Date, locale = 'fr-FR') => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    if (toLiteralMonthYearString(start, locale) === toLiteralMonthYearString(end, locale)) {
        return [toLiteralDayNumericString(start, locale), toLiteralDayLiteralMonthString(end, locale)]
    } else {
        return [toLiteralDayLiteralMonthString(start, locale), toLiteralDayLiteralMonthString(end, locale)]
    }
}

export function getNbDaysBetween(date1: Date, date2: Date) {
    const ONE_DAY = 1000 * 60 * 60 * 24
    const differenceMs = Math.abs(date1.getTime() - date2.getTime())
    return Math.round(differenceMs / ONE_DAY)
}

export const getWeekNumber = (date: Date, time = false): number => {
    return valueOfJsDate(date, time).weekNumber
}

export const getLiteralDayWeekStartEnd = (lang: Lang, today: Date = new Date(), start: DayOfWeek = DayOfWeek.MONDAY, end: DayOfWeek = DayOfWeek.FRIDAY) => {
    const dayFromNumber = lang.daysOfWeek.map((d) => d.value).indexOf(start) +  1
    const dayToNumber = lang.daysOfWeek.map((d) => d.value).indexOf(end) + 1

    const day = today.getDay()
    const dayCountFromTo = (dayToNumber - dayFromNumber + 7) % 7
    const dayDiffCount = (7 - dayFromNumber + 7 + day) % 7 + 1

    const diff = today.getDate() - dayDiffCount + (day === 0 ? -dayDiffCount : 1)

    const firstWeekDay = new Date(today.setDate(diff))
    const lastWeekDay = new Date(firstWeekDay)
    lastWeekDay.setDate(lastWeekDay.getDate() + dayCountFromTo)

    return toLiteralDayIntervalParts(firstWeekDay, lastWeekDay, lang.locale)
}

export const getWeekStartEnd = (lang: Lang, today: Date = new Date(), start: DayOfWeek = DayOfWeek.MONDAY, end: DayOfWeek = DayOfWeek.FRIDAY) => {
    const dayFromNumber = lang.daysOfWeek.map((d) => d.value).indexOf(start) +  1
    const dayToNumber = lang.daysOfWeek.map((d) => d.value).indexOf(end) + 1

    const day = today.getDay()
    const dayCountFromTo = (dayToNumber - dayFromNumber + 7) % 7
    const dayDiffCount = (7 - dayFromNumber + 7 + day) % 7 + 1

    const diff = today.getDate() - dayDiffCount + (day === 0 ? -dayDiffCount : 1)

    const firstWeekDay = new Date(today.setDate(diff))
    const lastWeekDay = new Date(firstWeekDay)
    lastWeekDay.setDate(lastWeekDay.getDate() + dayCountFromTo)

    return toLiteralIntervalParts(firstWeekDay, lastWeekDay, lang)
}

export const buildWeeksYearNumber = (
    nbWeekToCompute: number,
    lang: Lang,
    workingDays: WorkingDaySettings = {
        from: DayOfWeek.MONDAY,
        to: DayOfWeek.FRIDAY,
        workingRange : {
            startSlot: 18,
            endSlot: 36
        }
    },
) => {
    return Array.from(Array(nbWeekToCompute).keys())
        .reverse()
        .map((index) => {
            const dateToCompute = substract(new Date(), index, TimeUnit.WEEKS)
            const weekNumberToCompute = getWeekNumber(dateToCompute)
            const yearToCompute = dateToCompute.getFullYear()
            const [fromliteral, toLiteral] = getWeekStartEnd(lang, dateToCompute, workingDays.from, workingDays.to)
            const [fromliteralDay, toLiteralDay] = getLiteralDayWeekStartEnd(lang, dateToCompute, workingDays.from, workingDays.to)
            return {
                label: `${lang.qvtManagerBoard.tableHeaders.weekPrefix}${weekNumberToCompute}`,
                key: `${yearToCompute}${weekNumberToCompute}`,
                literal: lang.worloadEvaluation.title(fromliteral, toLiteral),
                literalDay: lang.worloadEvaluation.title(fromliteralDay, toLiteralDay),
            }
        })
}

export const secondsToLiteralTime = (seconds: number) => {
    const secondNumber = Number(seconds)
    const heurs = Math.floor(secondNumber / 3600)
    const minutes = Math.floor((secondNumber % 3600) / 60)

    const hDisplay = heurs > 0 ? heurs + 'h ' : ''
    const mDisplay = minutes > 0 ? minutes + 'm' : ''
    return hDisplay + mDisplay
}

export enum DayOfWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}
