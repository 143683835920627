import { createUseStyles } from 'react-jss'
// import IconAssets from '../../assets/icon/IconAssets'
import { useUser } from '../../contexts'

import IconAssets from '../../assets/icon/IconAssets'
import { BadgeType } from '../../models/Badge'
import { Challenge } from '../../models/Challenge'
import { diff, periodTitle, TimeUnit } from '../dateUtils'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'


interface DayCounterProps {
    challenge: Challenge
}

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
    daysBeforeChallenge: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '90%',
    },
    calendarIcon: {
        width: '25px',
        height: '25px',
    },
    numberOfDays: {
        fontSize: 'var(--gd-size-highlighted-small-number)',
        fontWeight: 700,
        color: 'var(--gd-primary-color)',
        marginLeft: '15px',
    },
    beforeChallengeText: {
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 400,
        marginLeft: '5px',
    },
    challengeIcon: {
        width: '80px',
        height: '80px',
        marginTop: '10px',
    },
    challengeDescription: {
        color: 'var(--gd-secondary-dark-color)',
        width: '85%',
        lineHeight: '25px',
        textAlign: 'center',
        marginTop: '15px',
        marginBottom: '15px',
    },
    arrowIcon: {
        width: '30px',
        height: '30px',
        alignSelf: 'center',
        marginTop: '30px',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        numberOfDays: {
            alignSelf: 'center',
        },
        challengeIcon: {
            width: '70px',
            height: '70px',
            marginTop: '5px',
        },
        arrowIcon: {
            width: '20px',
            height: '20px',
        },
    },
})

export default function DayCounter({ challenge }: DayCounterProps) {
    if (challenge.startDate == null || challenge.endDate == null) {
        return null
    }
    const { lang } = useUser()
    const styles = useStyles()

    const period = {
        startDate: new Date(challenge.startDate),
        endDate: new Date(challenge.endDate),
    }

    return (
        <div className={styles.container}>
            <div className={styles.daysBeforeChallenge}>
                <div className={styles.calendarIcon}>{<IconAssets.Calendar />}</div>
                <span className={styles.numberOfDays}>
                    {`${lang.dayFirstLetter}-${diff(period.startDate, new Date(), TimeUnit.DAYS)}`}
                    <span className={styles.beforeChallengeText}>{lang.goal.challenge.beforeChallengeStartText}</span>
                </span>
            </div>
            <div className={styles.challengeIcon}>
                {challenge.badgeType === BadgeType.DRIVE && <IconAssets.Garbage />}
                {challenge.badgeType === BadgeType.MAIL && <IconAssets.Attachement />}
                {challenge.badgeType === BadgeType.DIGITAL_CLEANUP_DAY && <IconAssets.Cleanup />}
                {challenge.badgeType === BadgeType.INTERN_ATTACHMENT && <IconAssets.InternAttachment />}
                {challenge.badgeType === BadgeType.MAILSHOT && <IconAssets.MailShot />}
                {challenge.badgeType === BadgeType.IMAGE_HUNT && <IconAssets.ImageHunt />}
                {challenge.badgeType === BadgeType.VIDEO_HUNT && <IconAssets.VideoHunt />}
            </div>
            <span className={styles.challengeDescription}>
                {periodTitle(period.startDate, period.endDate, lang)}
                <br />
                {lang.goal.challenge.description(challenge)}
            </span>
        </div>
    )
}
