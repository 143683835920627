import { Customer } from '../../models'
import digigreen from './digigreen'

const digigreenBeta: Customer = {
    ...digigreen,
    name: 'digigreen_beta',
    disablePwa: true
}

export default digigreenBeta
