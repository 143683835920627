import { Lang } from '../../../../models'
import defaultLang from '../../../lang/fr'

const tenFr: Lang = {
    ...defaultLang,
    goal: {
        ...defaultLang.goal,
        individual: {
            ...defaultLang.goal.individual,
            button: {
                title: 'Moi',
                addIndicators: 'Ajoutez vos indicateurs',
                qvt: 'Mes indicateurs QVCT',
            },
        },
    },
    authentication: {
        title: "Bienvenue sur l'application Baromètre QVCT",
        description: [
            'Partagez la charge de travail ressentie et accédez à vos indicateurs QVCT numérique. Ce dashboard vous aide à adopter de meilleures routines de travail pour préserver vos ressources cognitives et celles de vos équipes.',
        ],
        buttonLabel: 'Se connecter avec Microsoft 365',
    },
    welcomePopupMessage: [
        "L'application Greet collecte les informations de profil de votre compte Microsoft 365 (nom, prénom, photo) ainsi que des données d'usage des applications Outlook, Teams, Onedrive, Sharepoint pour évaluer l'hyperconnexion dans un cadre de QVCT Numérique.",
        'Vous pourrez à tout moment supprimer votre compte via le menu disponible en cliquant sur votre photo de profil.',
    ],
}

export default tenFr
