import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { useUser } from '../../contexts'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'

interface Props {
    title: string
    description: string
    link: string
    illustrationImage: JSX.Element
}

const useStyles = createUseStyles({
    articleContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        borderRadius: 'var(--gd-border-normal-radius)',
        padding: '10px',
        gap: '8px',
        backgroundColor: 'var(--gd-blogs-primary-color)',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '100%',
        justifyContent: 'space-between',
    },
    textContainerSection: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    title: {
        padding: '10px 0 10px 0',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 'bold',
        lineHeight: '25px',
        color: 'var(--gd-blogs-primary-text-color)',
    },
    description: {
        color: 'var(--gd-blogs-primary-text-color)',
        fontSize: 'var(--gd-size-small-body-text)',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    linkContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-end',
        marginRight: '30px',
        columnGap: '12px',
        textDecoration: 'none',
    },
    readMoreText: {

        fontSize: 'var(--gd-size-body-text)',
        fontWeight: 'bold',
        color: 'var(--gd-blogs-primary-text-color)',
    },
    arrowLink: {
        decoration: 'none',
        color: 'var(--gd-blogs-primary-text-color)',
        width: '35px',
        height: '35px',
    },
    illustration: {
        width: '200px',
        height: '170px',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        articleContainer: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        title: {
            padding: '0',
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'self-start',
            justifyContent: 'center',
            width: '100%',
            gap: '1rem',
        },
        textWrapper: {
            justifyContent: 'space-between',
            padding: '10px 10px',
        },
        linkContainer: {
            justifyContent: 'space-between',
            alignItems: 'center',
            marginRight: '0',
            alignSelf: 'flex-end',
        },
        description: {
            maxWidth: '200px',
        },
        illustration: {
            width: '200px',
            height: '170px',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        articleContainer: {
            height: '230px',
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'self-start',
            justifyContent: 'center',
            width: '100%',
            gap: '1rem',
        },
        textWrapper: {
            justifyContent: 'space-between',
            padding: '10px 10px',
        },
        linkContainer: {
            justifyContent: 'space-between',
            alignItems: 'center',
            marginRight: '0',
            alignSelf: 'unset',
        },
        description: {
            maxWidth: '200px',
        },
    },
})

export default function Article({ title, description, link, illustrationImage }: Readonly<Props>) {
    const { lang } = useUser()
    const styles = useStyles()
    return (
        <div className={styles.articleContainer}>
            <div className={styles.illustration}>{illustrationImage}</div>
            <div className={styles.textContainer}>
                <div className={styles.textContainerSection}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.description}>{description}</div>
                </div>
                <a className={styles.linkContainer} href={link} target='_blank' rel='noopener noreferrer'>
                    <h5 className={styles.readMoreText}>{lang.home.blog.readMore}</h5>
                    <div className={styles.arrowLink}>
                        <IconAssets.ArrowRight color='var(--gd-blogs-secondary-color)' />
                    </div>
                </a>
            </div>
        </div>
    )
}
