import { Lang } from '../../../../models'
import { BadgeType } from '../../../../models/Badge'
import { Challenge } from '../../../../models/Challenge'
import defaultEn from '../../../lang/en'

const digireenEn: Lang = {
    ...defaultEn,
    authentication: {
        title: 'Make my digital practices more responsible',
        description: ['Measure the CO2 emissions of my uses on Microsoft 365 to develop sustainable digital work practices'],
        buttonLabel: 'Sign in with Microsoft 365',
    },
    presentation: {
        title: 'Make my digital practices more responsible',
        description: ['I start by reducing my CO2 emissions'],
    },
    goal: {
        ...defaultEn.goal,
        typeInfo: {
            carbonFootPrint: {
                title: 'Reduce my CO2 emissions',
                theme: 'Environment',
            },
            infobesity: {
                title: 'Control infobesity',
                theme: 'Operational efficiency',
            },
            disconnectionTime: {
                title: 'Ensure disconnection times',
                theme: 'Well being at work',
            },
        },
        home: {
            addGoal: 'Add',
            addGroupGoal: 'Add a goal for a group',
            see: 'See details',
            precision: 'compared to the previous 30 days',
            groupProgress: 'Group progress :',
            individualProgress: 'My progress :',
            addindividualIndicators: 'Add my indicators',
            addindividualIndicatorsDescription: 'Track my own progress with my indicators',
        },
        individual: {
            ...defaultEn.goal.individual,
            planetFriendlyBadge: {
                ...defaultEn.goal.individual.planetFriendlyBadge,
                title: 'Reduce my CO2 emissions',
                description: 'Each month, I get my Planet Friendly badge when I reduce my CO2 emissions by 4% for the month compared to last year',
            },
        },
        carbonCumul: {
            ...defaultEn.goal.carbonCumul,
            titleIndividuel: 'My trajectory',
            titleGroup: 'Annual trajectory',
            leftTextIndividuel: 'Reduce my CO2 emissions by 4% per year to comply with the Paris Agreement.',
            leftTextGroup: 'Reduce our CO2 emissions by 4% per year to comply with the Paris Agreement.',
            decile: {
                title: {
                    myPosition: 'My position :',
                    info: 'A decile represents one tenth of the total population. There are therefore 10 deciles',
                },
                position(firstQuintil, position) {
                    return `You've emitted ${firstQuintil ? 'less' : 'more'} than ${position}% of DigiGreen users in the company.`
                },
            },
        },
        weeklyCleaning: {
            ...defaultEn.goal.weeklyCleaning,
            description: 'Clean up my mailbox and my OneDrive space',
        },
        challenge: {
            ...defaultEn.goal.challenge,
            noChallenge: {
                simple: 'No scheduled challenge',
                animator: 'To make my group progress in its objective add a challenge',
            },
            beforeChallengeStartText: 'before the challenge starts',
            digitalCleanupDay: {
                ...defaultEn.goal.challenge.digitalCleanupDay,
                noDataTitle: 'Make my digital workspace lighter !',
                noDataText: 'I will clean up my Onedrive and mailbox.',
                after: {
                    ...defaultEn.goal.challenge.digitalCleanupDay.after,
                    title: 'Clean up my mailbox and my OneDrive space',
                },
            },
            description: (challenge: Challenge) => {
                switch (challenge.badgeType) {
                    case BadgeType.MAIL:
                        return `Limit attachments to ${challenge.mailGoalSize} MB`
                    case BadgeType.DRIVE:
                        return `Delete ${challenge.driveGoalSize} MB in my personal OneDrive`
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return 'Clean up my mailbox and my OneDrive space'
                    case BadgeType.MAILSHOT:
                        return `Limit the maximum number of recipients to ${challenge.mailShotGoalSize} per email this week`
                    case BadgeType.INTERN_ATTACHMENT:
                        return "Don't send any attachments internally this week"
                    case BadgeType.IMAGE_HUNT:
                        return `Delete ${challenge.imageHuntGoalSize} MB of images from your personal OneDrive this week`
                    case BadgeType.VIDEO_HUNT:
                        return `Delete ${challenge.videosHuntGoalSize} MB of videos from your personal OneDrive this week`
                    default:
                        return ''
                }
            },
        },
        carbonFootprint: {
            ...defaultEn.goal.carbonFootprint,
            title: 'Detail of my emissions',
        },
        cohort: {
            title: "They're changing their digital practices",
            joinCohort: 'Join the process by adding my personal indicators',
            noUserCohortMessage: 'Because we go further together, mobilize my teamates on the team objective!',
        },
        coach: {
            form: {
                description: {
                    part1: 'Description (part 1)',
                    part2: 'Description (part 2)',
                },
                coachContent: {
                    description: 'Coaches monitoring',
                    recommendedSize: '(Recommended size of pictures: 369 x 239)',
                    firstname: 'Firstname',
                    calendarURL: 'Calendar URL',
                    base64Picture: 'Base64 picture',
                    addPicture: 'Choose a picture',
                    addCoach: 'Add a coach',
                    removeCoach: 'Delete',
                },
                display: {
                    description: 'Choice of coach number',
                    oneCoach: 'One coach',
                    twoCoach: 'Two coaches',
                },
                save: 'Save',
                cancel: 'Cancel',
            },
            textBtn: 'Make an appointment with ',
        },
        collective: {
            ...defaultEn.goal.collective,
            presentation: {
                ...defaultEn.goal.collective.presentation,
                title: 'My team goal',
                noDescription: 'Define my team’s objective',
            },
        },
    },
    home: {
        ...defaultEn.home,
        victory: {
            ...defaultEn.home.victory,
            badges: {
                ...defaultEn.home.victory.badges,
                drive: {
                    ...defaultEn.home.victory.badges.drive,
                    comparedToLastYear: (removedDataMB: number) => `${removedDataMB} Mb deleted in my OneDrive`,
                },
            },
        },
        blog: {
            title: 'Blog',
            readMore: 'Read more',
        },
    },
    components: {
        select: {
            selectCohortDefaultLabel: 'My groups',
            defaultNoChoicesLabel: 'None of my groups are following this goal yet',
            AddYourOwnIndicatorsMsg: 'Start following this goal by adding my indicators',
        },
    },
    deleteAccountPopup: {
        title: 'Deletion of account',
        messages: [
            'By confirming, my Greet account will be deleted, my personal data will be erased.',
            'To finalize the operation, we ask you in the next screen to log out of your Microsoft 365 account.',
        ],
        confirmBtnText: 'Continue',
        cancelBtnText: 'Cancel',
    },
    welcomePopupMessage: [
        'DigiGreen application collects profile information from my Microsoft 365 account (last name, first name, photo) as well as usage data from Outlook, Teams, Onedrive applications to measure their impact on CO2 emissions.',
        'I can delete my account at any time via the menu available by clicking on my profile picture.',
    ],
    webinar: {
        ...defaultEn.webinar,
        startAt: 'September 18 at 11:30 am.',
        contentTitle: '“How to clean up your OneDrive spaces”',
        description: '',
        registerationUrl:
            'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NzdmOTEyMzUtOWZjZS00MDNhLThiOGEtZGUzZDQ3ZDE3NGVm%40thread.v2/0?context=%7b%22Tid%22%3a%223ee81190-954b-4064-8e7d-f12fd761fd39%22%2c%22Oid%22%3a%2246fa96b0-4b60-4713-8e9c-344f202cc138%22%7d',
        register: 'Connect',
    },
    welcomeBack: {
        ...defaultEn.welcomeBack,
        emissionCard2: {
            ...defaultEn.welcomeBack.emissionCard2,
            description: 'of DigiGreen users in the company.',
        },
    },
    accessDeniedPopupMessageWithLink: 'To obtain a DigiGreen access license, please fill in the following form : ',
    linkName: 'form'
}

export default digireenEn
