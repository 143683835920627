import { LeckoLogo } from '../../components/headers/LeckoLogo'
import { Customer, EGoal } from '../../models'

import WebinarImg from '../../assets/illustration/Webinar.png'

import { defaultBlogs, defaultBricks, defaultBugReport, defaultCoachingMeeting } from './defaultCustomerValues'

import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import { BrickTypes } from '../../models/Brick'
import ECoachingEvent from '../../models/coaching/ECoachingEvent'
import QVTIndividualGoal from '../../pages/QVTIndividualGoal'

const lecko: Customer = {
    name: 'lecko',
    platformName: 'lecko_365',
    logo: LeckoLogo,
    QVTIndividualGoal: QVTIndividualGoal,
    bricks: [
        ...defaultBricks,
        {
            type: BrickTypes.COPILOTE,
            name: 'Copilot',
        },
    ],
    theme: {
        primaryColor: '#59ABB1', //                                            --gd-primary-color
        primaryDarkColor: '#3E9097', //                                        --gd-primary-dark-color
        secondaryColor: '#2067DC', //                                          --gd-secondary-color
        secondaryDarkColor: '#215CBB', //                                      --gd-secondary-dark-color
        tertiaryColor: '#F4991D', //                                           --gd-tertiary-color

        borderSmallRadius: '10px', //                                          --gd-border-small-radius
        borderNormalRadius: '20px', //                                         --gd-border-normal-radius
        borderBigRadius: '50px', //                                            --gd-border-big-radius

        positiveColor: '#4DAD33', //                                           --gd-positive-color
        intermediaryColor: '#F4991D', //                                       --gd-intermediary-color
        negativeColor: '#ED2C2C', //                                           --gd-negative-color

        primaryButtonColor: 'var(--gd-primary-color)', //                      --gd-primary-button-color
        secondaryButtonColor: 'var(--gd-secondary-color)', //                  --gd-secondary-button-color
        dangerButtonColor: 'var(--gd-negative-color)', //                      --gd-danger-button-color

        primaryButtonTextColor: 'var(--gd-text-clear-color)', //                --gd-primary-button-text-color
        secondaryButtonTextColor: 'var(--gd-text-clear-color)', //              --gd-secondary-button-text-color
        dangerButtonTextColor: 'var(--gd-text-clear-color)', //                 --gd-secondary-button-text-color

        mainAppBackgroundColor: '#FFFFFF', //                                  --gd-main-app-background-color
        mainAppShadowedContent: 'rgb(0 0 0 / 8%) 0px -15px 15px 0px', //       --gd-main-app-shadowed-content
        mainActiveTabBackgroundColor: 'var(--gd-background-clear-color)', //   --gd-main-active-tab-background-color
        mainActiveTabColor: 'var(--gd-secondary-color)', //                    --gd-main-active-tab-color
        mainPopupBackgroundColor: '#FFFFFF', //                                --gd-main-popup-background-color

        blogsPrimaryColor: 'var(--gd-secondary-color)', //                     --gd-blogs-primary-color
        blogsPrimaryDarkColor: 'var(--gd-secondary-dark-color)', //            --gd-blogs-primary-color
        blogsSecondaryColor: 'var(--gd-primary-color)', //                     --gd-blogs-secondary-color

        blogsPrimaryTextColor: 'var(--gd-text-clear-color)', //                --gd-blogs-primary-text-color
        blogsPrimaryDarkTextColor: 'var(--gd-text-clear-color)', //            --gd-blogs-primary-dark-text-color
        blogsSecondaryTextColor: 'var(--gd-text-clear-color)', //              --gd-blogs-secondary-text-color

        backgroundClearColor: '#F0F6FF', //                                    --gd-background-clear-color
        backgroundDarkColor: '#D1DFF5', //                                     --gd-background-dark-color

        textClearColor: '#FFFFFF', //                                          --gd-text-clear-color
        textDarkColor: '#1C1B1F', //                                           --gd-text-dark-color
        textTertiaryColor: '#FFFFFF', // same value as gd-text-clear-color //  --gd-text-tertiary-color
        textMainSubTitleColor: '#3E9097', //                                   --gd-text-main-subtitle-color
        bigTextDarkColor: '#1C1B1F', //                                        --gd-big-text-dark-color

        brickEmailColor: '#002F7D', //                                         --gd-brick-email-color
        brickEmailActiveColor: '#F0F6FF', //                                   --gd-brick-email-active-color
        brickOneDriveColor: '#0F7CCF', //                                      --gd-brick-onedrive-color
        brickOneDriveActiveColor: '#F0F6FF', //                                --gd-brick-onedrive-active-color
        brickTeamsColor: '#3859CC', //                                         --gd-brick-teams-color
        brickTeamsActiveColor: '#F0F6FF', //                                   --gd-brick-teams-active-color
        brickSharepointColor: '#267379', //                                    --gd-brick-sharepoint-color
        brickSharepointActiveColor: '#F0F6FF', //                              --gd-brick-sharepoint-active-color
        brickVisionColor: '#58BDC5', //                                        --gd-brick-vision-color
        brickVisionActiveColor: '#58BDC5', //                                  --gd-brick-vision-active-color
        brickChatColor: '#59ABB1', //                                          --gd-brick-chat-color
        brickGmailColor: '#4285f4', //                                         --gd-brick-gmail-color
        brickGmailActiveColor: '#1C1B1F', //                                   --gd-brick-gmail-active-color
        brickGDriveColor: '#FBBC04', //                                        --gd-brick-gdrive-color
        brickGDriveActiveColor: '#1C1B1F', //                                  --gd-brick-gdrive-active-color
        brickSharedGDriveColor: '#9AA0A6', //                                  --gd-brick-shared-gdrive-color
        brickSharedGDriveActiveColor: '#1C1B1F', //                            --gd-brick-shared-gdrive-active-color

        brickCopiloteColor: '#A86EDD', //                                      --gd-brick-copilote-color
        brickCopiloteActiveColor: '#F0F6FF', //                                --gd-brick-copilote-active-color

        goalTextColor: 'var(--gd-text-dark-color)', //                         --gd-goal-text-color
        goalFillColor: '#FFF', //                                              --gd-goal-fill-color
        goalCarbonFootprintColor: '#073C93', //                                --gd-goal-carbon-footprint-color
        goalInfobisityColor: '#629CFD', //                                     --gd-goal-infobesity-color
        goalDeconnectionTimeColor: '#00AEE4', //                               --gd-goal-deconnection-time-color

        badgeTextColor: 'var(--gd-text-dark-color)', //                        --gd-badge-text-color
        badgeFillColor: '#FFF', //                                             --gd-badge-fill-color
        badgeMailsOnDietColor: '#1EAFE2', //                                   --gd-badge-mails-on-diet-color
        badgeMailsOnDietLineColor: '#1EAFE2', //                               --gd-badge-mails-on-diet-line-color
        badgeMailsOnDietFillColor: '#1EAFE2', //                               --gd-badge-mails-on-diet-fill-color
        badgeCleanUpColor: '#ED6E2C', //                                       --gd-badge-cleanup-color
        badgeCleanUpLineColor: '#ED6E2C', //                                   --gd-badge-cleanup-line-color
        badgeCleanUpFillColor: '#ED6E2C', //                                   --gd-badge-cleanup-fill-color
        badgePlanetFriendlyColor: '#4DAD33', //                                --gd-badge-planet-friendly
        badgePlanetFriendlyLineColor: '#4DAD33', //                            --gd-badge-planet-line-friendly
        badgePlanetFriendlyFillColor: '#4DAD33', //                            --gd-badge-planet-fill-friendly
        badgeInternAttachmentColor: '#ED6E2C', //                              --gd-badge-intern-attachment-color
        badgeInternAttachmentLineColor: '#ED6E2C', //                          --gd-badge-intern-attachment-line-color
        badgeInternAttachmentFillColor: '#ED6E2C', //                          --gd-badge-intern-attachment-fill-color
        badgeMailShotColor: '#EF4E78', //                                      --gd-badge-mailshot-color
        badgeMailShotLineColor: '#EF4E78', //                                  --gd-badge-mailshot-line-color
        badgeMailShotFillColor: '#EF4E78', //                                  --gd-badge-mailshot-fill-color
        badgeImageHuntColor: '#EF4E78', //                                     --gd-badge-imagehunt-color
        badgeImageHuntLineColor: '#EF4E78', //                                 --gd-badge-imagehunt-line-color
        badgeImageHuntFillColor: '#EF4E78', //                                 --gd-badge-imagehunt-fill-color
        badgeVideoHuntColor: '#1EAFE2', //                                     --gd-badge-video-hunt-color
        badgeVideoHuntLineColor: '#1EAFE2', //                                 --gd-badge-video-hunt-line-color
        badgeVideoHuntFillColor: '#1EAFE2', //                                 --gd-badge-video-hunt-fill-color
        loaderColors: ['#2067DC', '#AED5D8', '#AED5D8', '#FFFFFF', '#AED5D8'],

        emailSendingRangeLessThanTen: '#9BCDD0', //                            --gd-email-sending-range-stick-less-than-ten-fill-color
        emailSendingRangeBetweenTenAndThirty: '#59ABB1', //                    --gd-email-sending-range-stick-beetwen-ten-and-thirty-fill-color
        emailSendingRangeMoreThanThirty: '#2D5659', //                         --gd-email-sending-range-stick-more-than-thirty-fill-color

        emailSendingRangeSmallRange: '#d6d6d6', //                             --gd-email-sending-range-small-range-color
        emailSendingRangeMediumRange: '#939393', //                            --gd-email-sending-range-medium-range-color
        emailSendingRangeLargeRange: '#555555', //                              --gd-email-sending-range-large-range-color
        emailSendingRangeHeightRange: '#1C1B1F', //                            --gd-email-sending-range-height-range-color
    },
    goals: [EGoal.CARBON_FOOTPRINT, EGoal.QVT],
    contact: {
        support: {
            name: 'Arnaud Rayrole',
            mail: 'arayrole@lecko.fr',
        },
        hasLinkSupport: false,
        link: '',
    },
    legalInformation: {
        cgu: 'https://leckogreet.blob.core.windows.net/lecko/Greet_CGU_Lecko_Oct_2023.pdf',
        charter: 'https://leckogreet.blob.core.windows.net/lecko/Greet_Charte_de_protection_des_donnees_personnelles_Oct_2023.pdf',
    },
    webinarImg: WebinarImg,
    goalPagesLayout: {
        coachingOnCollective: {
            tips: true,
            training: true,
            coaching: true,
        },
    },

    bugReport: defaultBugReport,
    defaultCoachingMeeting: defaultCoachingMeeting,
    coachingEvent: ECoachingEvent.WEBINAR,
    featuring: [GreetFeaturingTarget.RANKING, GreetFeaturingTarget.RANKING_VICTORIES],
    blogs: defaultBlogs
}

export default lecko
