import { createUseStyles } from 'react-jss'
import { useUser } from '../contexts'
import Payload from '../models/Payload'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import Rate from './Rate'
import {
    periodTitle,
    substractDays,
} from './dateUtils'
import { convertToGCO2 } from './utils'

interface Props {
    payload: Payload | undefined
}

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '360px',
        minWidth: '360px',
        height: '280px',
        padding: '15px 0',
        backgroundColor: 'var(--gd-secondary-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        gap: '0.5rem',
    },
    periodContainer: {
        display: 'flex',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: 'var(--gd-border-big-radius)',
        padding: '15px',
        height: '35px',
        width: '80%',
    },
    periodText: {
        fontWeight: 'bold',
        color: 'var(--gd-text-dark-color)',
    },
    carbonfootprintContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '15px',
        width: '90%',
    },
    detailsText: {
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'bold',
        color: 'var(--gd-text-tertiary-color)',
        width: '80%',
        marginTop: '15px',
        textAlign: 'center',
    },
    rateValue: {
        fontSize: 'var(--gd-size-highlighted-number)',
        fontWeight: 'bold',
        color: 'var(--gd-text-tertiary-color)',
        marginRight: '4px',
    },
    rateWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
        backgroundColor: '#FFFFFF',
        borderRadius: 'var(--gd-border-normal-radius)',
        height: '45px',
        width: '50%',
    },
    comparedToText: {
        fontSize: 'var(--gd-size-small-body-text)',
        color: 'var(--gd-text-tertiary-color)',
        textAlign: 'center',
        marginTop: '10px',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        container: {
            width: '100%',
            maxWidth: 'unset',
            minWidth: 'unset',
        },
    },
})

export default function CarbonFootprintRate({ payload }: Props) {
    if (payload == null) {
        return null
    }

    const { lang } = useUser()
    const styles = useStyles()

    const carbonFootprint = convertToGCO2(payload.mail.carbonImpact + payload.drive.carbonImpact + payload.teams.carbonImpact +
        (payload.sharedDrive.carbonImpact ?? 0) + (payload.copilote?.carbonImpact ?? 0))

    const rate = Math.round(payload?.evolution ?? 0)

    const period = periodTitle(substractDays(payload.calculationDate, 30), substractDays(payload.calculationDate, 1), lang)

    return (
        <div className={styles.container}>
            <div className={styles.periodContainer}>
                <span className={styles.periodText}>{period}</span>
            </div>
            {carbonFootprint.value ? (
                <span className={styles.detailsText}>
                    <span className={styles.rateValue}>{`${carbonFootprint.value} ${carbonFootprint.measured}`}</span>
                    <span>{lang.goal.carbonFootprint.evolution.details}</span>
                </span>
            ) : (
                <div className={styles.carbonfootprintContainer}>
                    <span className={styles.rateValue}>{lang.goal.carbonFootprint.measuredDataMissing}</span>
                </div>
            )}
            <div className={styles.rateWrapper}>
                <Rate value={rate} />
            </div>
            <span className={styles.comparedToText}>{lang.goal.carbonFootprint.evolution.comparedTo}</span>
        </div>
    )
}
