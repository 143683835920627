import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import { Customer } from '../../models'
import lecko from './lecko'

const cogx: Customer = {
    ...lecko,
    name: 'cogx',
    disablePwa: true,
    featuring: [GreetFeaturingTarget.RANKING, GreetFeaturingTarget.RANKING_VICTORIES, GreetFeaturingTarget.DRIVE_FOLDER_DELETION, GreetFeaturingTarget.DRIVE_VERSION],
}

export default cogx
