import { CategoryScale, Chart as ChartJS, ChartOptions, LinearScale, LineElement, PointElement, TimeScale } from 'chart.js'
import 'chartjs-adapter-luxon'
import { useEffect, useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { createUseStyles } from 'react-jss'
import { useInstance, useUser } from '../contexts'

import IconAssets from '../assets/icon/IconAssets'
import { Lang } from '../models'
import CarbonImpactIndicatorsDailyDto from '../models/CarbonImpactIndicatorsDaily'
import CohortPayload from '../models/CohortPayload'
import UserPayload from '../models/UserPayload'
import { dateToISOStringWithoutTime } from './dateUtils'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import buildGreetLegend from './share/chartjs/GreetLegend'
import { buildGreetTooltip } from './share/chartjs/GreetTooltip'
import buildGreetxAxePeriod from './share/chartjs/GreetxAxePeriod'
import GreetFeaturingComponent from './share/GreetFeaturingComponent'
import InfoBulle from './share/Infobulle'
import Title from './Title'
import { convertToGCO2, convertToRem } from './utils'

const TAUX_REDUCTION_CARBON = 0.96

ChartJS.register(CategoryScale, LinearScale, TimeScale, PointElement, LineElement)

interface CarbonCumulGraphProps {
    payload: CohortPayload | UserPayload | undefined
    title: string
    carbonEmissionsText: string
    carbonEmissionsLeftText: string
}

const useStyles = createUseStyles({
    container: {
        width: 'calc(78% - 30px)', // 30px is the row gap, defined on challengeCarbonCumulGraphContainer class (CollectiveGoal component)
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    card: {
        width: '100%',
        height: '313px',
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'center',
        backgroundColor: 'var(--gd-primary-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
    },
    cardLeftPart: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30%',
        flexDirection: 'column',
        borderRadius: 'var(--gd-border-normal-radius)',
        padding: '1rem',
    },
    cardRightPart: {
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 'var(--gd-border-normal-radius)',
        backgroundColor: 'var(--gd-background-clear-color)',
        padding: '1rem',
    },
    graphContainer: {
        display: 'inline-block',
        position: 'relative',
        width: '90%',
        height: '100%',
        maxHeight: '360px',
    },
    text1: {
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'bold',
        color: 'var(--gd-text-clear-color)',
        marginBottom: '20px',
    },
    mobileTitle: {
        display: 'none',
    },
    title: {
        display: 'block',
    },
    text2: {
        fontSize: 'var(--gd-size-small-body-text)',
        color: 'var(--gd-text-clear-color)',
        marginBottom: '20px',
    },
    pill: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: 'var(--gd-border-big-radius)',
        padding: '0.5rem 1.5rem',
        color: 'var(--gd-primary-color)',
        fontWeight: 'bold',
        fontSize: 'var(--gd-size-highlighted-title)',
    },
    pillNumber: {
        fontWeight: 'bold',
        fontSize: 'var(--gd-size-highlighted-number)',
        marginRight: '5px',
    },
    pillText: {
        marginTop: '5px',
    },
    decile: {
        position: 'relative',
        width: convertToRem(173),
        alignSelf: 'start',
        display: 'flex',
        flexDirection: 'column',
        marginTop: convertToRem(12),
        color: 'var(--gd-text-clear-color)',
        fontSize: 'var(--gd-fixed-size-small-body-text)',
    },
    decileTitle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        '& svg': {
            position: 'absolute',
            top: convertToRem(1.5),
            right: 0,
            width: convertToRem(14),
            height: convertToRem(14),
            '& path': {
                fill: 'currentcolor',
            },
        },
    },
    decilePosition: {
        marginTop: convertToRem(4),
        padding: `${convertToRem(7)} ${convertToRem(6)} ${convertToRem(9)} ${convertToRem(13)}`,
        borderRadius: 'var(--gd-border-small-radius)',
        backgroundColor: 'var(--gd-primary-dark-color)',
        textAlign: 'unset',
        fontWeight: '700',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        container: {
            display: 'flex',
            width: '100%',
            maxWidth: '100vw',
            flexDirection: 'column',
            gap: '1rem',
            backgroundColor: 'var(--gd-background-clear-color)',
            borderRadius: 'var(--gd-border-normal-radius)',
            padding: '10px 0',
        },
        card: {
            backgroundColor: 'unset',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
        },
        cardLeftPart: {
            width: '100%',
        },
        cardRightPart: {
            backgroundColor: 'unset',
            justifyContent: 'center',
            alignContent: 'center',
            textAlign: 'center',
            width: '100%',
            height: '100%',
            padding: '0rem',
        },
        pill: {
            padding: '0.5rem 1rem',
            color: 'var(--gd-secondary-dark-color)',
        },
        graphContainer: {
            height: '35vh',
            width: '95%',
        },
        title: {
            display: 'none',
        },
        mobileTitle: {
            display: 'block',
            textAlign: 'center',
            lineHeight: '25px',
            fontSize: 22,
            fontWeight: 'bold',
            color: 'var(--gd-secondary-dark-color)',
        },
        text1: {
            fontWeight: 'bold',
            color: 'var(--gd-secondary-dark-color)',
            marginBottom: '20px',
            textAlign: 'center',
        },
        text2: {
            color: 'var(--gd-secondary-dark-color)',
            marginBottom: '20px',
            textAlign: 'center',
        },
        decile: {
            marginTop: convertToRem(15.84),
            width: '100%',
            color: 'unset',
        },
        decileTitle: {
            display: 'unset',
            textAlign: 'center',
            fontWeight: 'bold',
            '& svg>path': {
                fill: 'var(--gd-secondary-color)',
            },
        },
        decilePosition: {
            display: 'inline-block',
            marginTop: convertToRem(8),
            padding: `${convertToRem(7)} unset ${convertToRem(1)} unset`,
            backgroundColor: 'var(--gd-background-dark-color)',
            textAlign: 'center',
            '&>strong': {
                display: 'unset',
            },
        },
    },
    [MediaQueryBreakpoints.TABLETTE]: {
        container: {
            width: '100%',
        },
        card: {
            flexDirection: 'column',
            justifyContent: 'normal',
            height: '100%',
        },
        cardLeftPart: {
            width: '100%',
        },
        cardRightPart: {
            width: '100%',
            height: '100%',
        },
        pill: {
            padding: '0.5rem 1rem',
        },
        decile: {
            marginTop: convertToRem(15.84),
            width: '50%',
            alignSelf: 'center',
        },
        decileTitle: {
            display: 'unset',
            textAlign: 'center',
        },
        decilePosition: {
            marginTop: convertToRem(8),
        },
    },
    [MediaQueryBreakpoints.TABLETTE_XL]: {
        container: {
            width: '100%',
        },
    },
})

export default function CarbonImpact({ title, carbonEmissionsLeftText, carbonEmissionsText, payload }: Readonly<CarbonCumulGraphProps>) {
    const { customer } = useInstance()
    const { lang } = useUser()
    const customerTheme = customer.theme
    const styles = useStyles()
    const [graphValues, setGraphValues] = useState<ComponentStorage>(newComponentStorage())

    useEffect(() => {
        if (payload == null) {
            return
        }

        // We have to clone the object to avoid to modifying the object stored in the parent
        const lastYearRes: CarbonImpactIndicatorsDailyDto[] = structuredClone(payload?.consolidatedCarbonImpact?.daysOfLastYear ?? [])
        const currentYearRes: CarbonImpactIndicatorsDailyDto[] = structuredClone(payload?.consolidatedCarbonImpact?.daysOfCurrentYear ?? [])

        const ncs: ComponentStorage = newComponentStorage()
        const currentYear = new Date().getFullYear()

        let lastYearCarbonCumul = 0
        let lastYearValidDays = 0
        let lastElementIsValide = false
        // last year
        for (const elem of lastYearRes) {
            lastYearCarbonCumul += elem.ci

            if (isThe29February(currentYear, elem.d)) {
                continue
            }

            if (elem.v) {
                ncs.carbonCumulArrayLastYear.push(lastYearCarbonCumul)
                lastElementIsValide = true
            } else if (!lastElementIsValide) {
                // on remplis avec des undefined pour décaler la courbe et on arrête sur le dernier element valide
                // Pas d'undefined après des éléments valides (généralement la dernière valeur) afin d'éviter des sauts de la courbe
                ncs.carbonCumulArrayLastYear.push(undefined)
            }

            // elem.date[0] = currentYear // so both lines can be together
            ncs.daysThisYear.push(dateToISOStringWithoutTime(elem.d))
            if (elem.ci > 0) {
                lastYearValidDays++
            }
        }

        ncs.daysThisYear.sort((a, b) => a.localeCompare(b))
        ncs.lastYearCarbonCumul = lastYearCarbonCumul

        let currentYearCarbonCumul = 0
        lastElementIsValide = false
        // current year
        for (const elem of currentYearRes) {
            currentYearCarbonCumul += elem.ci

            if (isThe29February(currentYear, elem.d)) {
                continue
            }
            if (elem.v) {
                ncs.carbonCumulArrayCurrentYear.push(currentYearCarbonCumul)
                lastElementIsValide = true
            } else if (!lastElementIsValide) {
                // on remplis avec des undefined pour décaler la courbe et on arrête sur le dernier element valide
                // Pas d'undefined après des éléments valides (généralement la dernière valeur) afin d'éviter des sauts de la courbe
                ncs.carbonCumulArrayCurrentYear.push(undefined)
            }
        }
        ncs.currentYearCarbonCumul = currentYearCarbonCumul

        // last year's max
        for (const elem of lastYearRes) {
            if (isThe29February(currentYear, elem.d)) {
                continue
            }

            ncs.maxOfLastYearArray.push(lastYearCarbonCumul)
        }

        const objective = lastYearCarbonCumul * (365 / lastYearValidDays) * TAUX_REDUCTION_CARBON
        // If history has more less than two months, no objective is set else we set the objective in proportion to the number of days of the year
        if (lastYearValidDays > 61) {
            ncs.hasObjectiveOfCurrentYear = true
            ncs.objectiveOfCurrentYear = objective
        }

        const objectivePlusReduction = objective * (1 / TAUX_REDUCTION_CARBON)
        const gapForLastYearLine = objectivePlusReduction - ncs.lastYearCarbonCumul
        for (let i = 0; i < lastYearRes.length; i++) {
            if (!Number.isNaN(ncs.carbonCumulArrayLastYear[i])) {
                ncs.carbonCumulArrayLastYear[i] = (ncs.carbonCumulArrayLastYear[i] ?? 0) + gapForLastYearLine
            }
            ncs.objectiveOfCurrentYearArray.push(ncs.objectiveOfCurrentYear)
        }

        setGraphValues(ncs)
    }, [payload])

    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 15,
                right: 15,
                bottom: 15,
            },
        },
        scales: {
            x: {
                display: true,
                type: 'time',
                time: {
                    unit: 'month',
                    displayFormats: {
                        month: dateFormat,
                    },
                },
                ticks: {
                    callback: function (scale, tickValue) {
                        return dateNumberToLetter[tickValue]
                    },
                    font: {
                        weight: 'bold',
                    },
                    color: customerTheme.textDarkColor,
                },
                grid: {
                    color: customerTheme.backgroundDarkColor,
                },
            },
            y: {
                display: true,
                ticks: {
                    display: false,
                    stepSize: Math.max(graphValues.lastYearCarbonCumul, graphValues.currentYearCarbonCumul) / 8,
                },

                grid: {
                    color: customerTheme.backgroundDarkColor,
                },
            },
        },

        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        spanGaps: false,
        devicePixelRatio: 2,
    }
    const data = {
        labels: graphValues.daysThisYear,
        datasets: [
            {
                data: graphValues.carbonCumulArrayCurrentYear,
                backgroundColor: customerTheme.primaryColor,
                borderColor: customerTheme.primaryColor,
                pointRadius: 0,
                pointHoverRadius: 0,
                pointHitRadius: 30,
                lineTension: 0, // draw straight lines
            },
            {
                data: graphValues.carbonCumulArrayLastYear,
                backgroundColor: customerTheme.backgroundDarkColor,
                borderColor: customerTheme.backgroundDarkColor,
                pointRadius: 0,
                pointHoverRadius: 0,
                pointHitRadius: 30,
                lineTension: 0, // draw straight lines
            },
        ],
    }

    const chartPlugins = useMemo(() => {
        const plugins = []
        if (graphValues.hasObjectiveOfCurrentYear) {
            plugins.push(
                buildGreetLegend({
                    objectifValue: graphValues.objectiveOfCurrentYear,
                    text: `${lang.goal.carbonCumul.goal} ${new Date().getFullYear()} : ${convertToGCO2(graphValues.objectiveOfCurrentYear).value} ${
                        convertToGCO2(graphValues.objectiveOfCurrentYear).measured
                    }`,
                    color: customerTheme.positiveColor,
                    fontSize: '14px',
                }),
            )
        }
        plugins.push(
            buildGreetTooltip({
                datasetIndex: 0,
                rate: payload?.evolution ?? 0,
                period: lang.goal.carbonCumul.toolTip,
                backgroundColor: customerTheme.secondaryColor,
                spotColor: customerTheme.primaryColor,
                textColor: customerTheme.textTertiaryColor,
            }),
        )
        plugins.push(
            buildGreetxAxePeriod({
                color: customerTheme.secondaryDarkColor,
            }),
        )
        return plugins
    }, [graphValues, lang])

    const pl = payload as CohortPayload
    const numberOfUsers = pl?.numberOfUsers ? pl.numberOfUsers : 1

    const decile = (payload as UserPayload)?.ranking?.decile ?? null

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <Title title={title} />
            </div>
            <div className={styles.mobileTitle}>{title}</div>
            <div className={styles.card}>
                <div className={styles.cardLeftPart}>
                    <div className={styles.text1}>{carbonEmissionsLeftText}</div>

                    <div className={styles.text2}>{carbonEmissionsText}</div>

                    <div className={styles.pill}>
                        <h2 className={styles.pillNumber}>{convertToGCO2(graphValues.currentYearCarbonCumul / numberOfUsers).value}</h2>
                        <div className={styles.pillText}>{convertToGCO2(graphValues.currentYearCarbonCumul / numberOfUsers).measured}</div>
                    </div>

                    <GreetFeaturingComponent {...{ decile, lang }} nextDef={Decile} legacyDef={() => null} />
                </div>
                <div className={styles.cardRightPart}>
                    <div className={styles.graphContainer}>
                        <Line
                            // https://github.com/reactchartjs/react-chartjs-2/issues/90
                            key={[...Array(36)].map(() => (~~(Math.random() * 36)).toString(36)).join('')}
                            id='chart'
                            options={options}
                            plugins={chartPlugins}
                            data={data}
                            redraw
                            updateMode='resize'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const Decile = ({ decile, lang }: { decile: number | null; lang: Lang }) => {
    const styles = useStyles()

    if (decile == null) {
        return null
    }

    const tauxDecile = decile <= 5 ? 100 - decile * 10 : (decile + -1) * 10

    return (
        <div className={styles.decile}>
            <div className={styles.decileTitle}>
                <span>{lang.goal.carbonCumul.decile.title.myPosition}</span>
                <InfoBulle text={lang.goal.carbonCumul.decile.title.info}>
                    <IconAssets.Information />
                </InfoBulle>
            </div>
            <div className={styles.decilePosition}>{lang.goal.carbonCumul.decile.position(decile <= 5, tauxDecile)}</div>
        </div>
    )
}

function isThe29February(currentYear: number, date: Date | string): boolean {
    const thisDate = new Date(date)
    return (
        thisDate.getFullYear() === currentYear &&
        thisDate.getMonth() === 1 && // february
        thisDate.getDate() === 29
    )
}

function newComponentStorage(): ComponentStorage {
    return {
        objectiveOfCurrentYear: 0,
        hasObjectiveOfCurrentYear: false,
        carbonCumulArrayLastYear: [],
        carbonCumulArrayCurrentYear: [],

        currentYearCarbonCumul: 0,
        lastYearCarbonCumul: 0,
        maxOfLastYearArray: [],
        objectiveOfCurrentYearArray: [],
        daysThisYear: [],
    }
}

type ComponentStorage = {
    objectiveOfCurrentYear: number
    hasObjectiveOfCurrentYear: boolean
    carbonCumulArrayLastYear: Array<number | undefined>
    carbonCumulArrayCurrentYear: Array<number | undefined>

    currentYearCarbonCumul: number
    lastYearCarbonCumul: number
    maxOfLastYearArray: Array<number>
    objectiveOfCurrentYearArray: Array<number>

    daysThisYear: Array<string> // ISO dates
}

const dateFormat = 'M'
const dateNumberToLetter: Record<string, string> = {
    '0': 'J',
    '1': 'F',
    '2': 'M',
    '3': 'A',
    '4': 'M',
    '5': 'J',
    '6': 'J',
    '7': 'A',
    '8': 'S',
    '9': 'O',
    '10': 'N',
    '11': 'D',
}
