import { useState } from 'react'
import { useInstance, useUser } from '../../contexts'
import { useFetch } from '../../services/api/useFetch'
import Popup from '../Popup'
import WorkloadEvaluation from './WorkloadEvaluation'

const WorkloadEvaluationPopup = () => {
    const { customer } = useInstance()
    const { user } = useUser()
    const [showWorkloadEvaluation, setShowWorkloadEvaluation] = useState<boolean>(!!customer.canViewOnlyQVTGoal && !!customer.workloadEvaluation)
    const { loading, data } = useFetch({ endpoint: 'workload-evaluation' })
    if (!showWorkloadEvaluation || loading) {
        return null
    }
    const onClose = () => {
        setShowWorkloadEvaluation(false)
    }
    const isPopupClosable = user?.isImpersonate ?? false
    return (
        <Popup onCloseRequest={onClose} closeOnOutside={isPopupClosable} closable={isPopupClosable}>
            <WorkloadEvaluation close={onClose} userData={data} />
        </Popup>
    )
}

export default WorkloadEvaluationPopup
