import { Lang } from '../../../../models'
import defaultLang from '../../../lang/en'

const tenEn: Lang = {
    ...defaultLang,
    goal: {
        ...defaultLang.goal,
        individual: {
            ...defaultLang.goal.individual,
            button: {
                title: 'Me',
                addIndicators: 'Add your indicators',
                qvt: 'My QWLC indicators',
            },
        },
    },
    authentication: {
        title: 'Welcome to QWLC Barometer application',
        description: [
            'Share your perceived workload and access your digital QWL indicators. This dashboard helps you adopt better work routines to preserve your cognitive resources and those of your teams.',
        ],
        buttonLabel: 'Sign in with Microsoft 365',
    },
    welcomePopupMessage: [
        'Greet application collects profile information from your Microsoft 365 account (last name, first name, photo) as well as usage data from Outlook, Teams, Onedrive, Sharepoint applications to evaluate hyperconnection in QWLC setting.',
        'You can delete your account at any time via the menu available by clicking on your profile picture.',
    ],
}

export default tenEn
