import { FFTLogo } from '../../components/headers/FFTLogo'
import { Customer, EGoal } from '../../models'

import WebinarImg from '../../assets/illustration/Webinar.png'

import ECoachingEvent from '../../models/coaching/ECoachingEvent'
import QVTIndividualGoal from '../../pages/QVTIndividualGoal'
import fftEn from './cutomerLangs/fft/fftEn'
import fftFr from './cutomerLangs/fft/fftFr'
import { defaultBlogs, defaultBricks, defaultBugReport, defaultCoachingMeeting } from './defaultCustomerValues'

const fft: Customer = {
    name: 'fft',
    platformName: 'fft',
    logo: FFTLogo,
    QVTIndividualGoal: QVTIndividualGoal,
    bricks: defaultBricks,
    theme: {
        primaryColor: '#D25711', //                                            --gd-primary-color
        primaryDarkColor: '#AA460D', //                                        --gd-primary-dark-color
        secondaryColor: '#003B68', //                                          --gd-secondary-color
        secondaryDarkColor: '#002642', //                                      --gd-secondary-dark-color
        tertiaryColor: '#003B68', //                                           --gd-tertiary-color

        borderSmallRadius: '10px', //                                          --gd-border-small-radius
        borderNormalRadius: '20px', //                                         --gd-border-normal-radius
        borderBigRadius: '50px', //                                            --gd-border-big-radius

        positiveColor: '#4DAD33', //                                           --gd-positive-color
        intermediaryColor: '#F4991D', //                                       --gd-intermediary-color
        negativeColor: '#E05858', //                                           --gd-negative-color

        primaryButtonColor: 'var(--gd-primary-color)', //                      --gd-primary-button-color
        secondaryButtonColor: 'var(--gd-primary-color)', //                    --gd-secondary-button-color
        dangerButtonColor: 'var(--gd-primary-color)', //                      --gd-danger-button-color

        primaryButtonTextColor: 'var(--gd-text-clear-color)', //                --gd-primary-button-text-color
        secondaryButtonTextColor: 'var(--gd-text-clear-color)', //              --gd-secondary-button-text-color
        dangerButtonTextColor: 'var(--gd-text-clear-color)', //                 --gd-secondary-button-text-color

        blogsPrimaryTextColor: 'var(--gd-text-clear-color)', //                --gd-blogs-primary-text-color
        blogsPrimaryDarkTextColor: 'var(--gd-text-clear-color)', //            --gd-blogs-primary-dark-text-color
        blogsSecondaryTextColor: 'var(--gd-text-clear-color)', //              --gd-blogs-secondary-text-color

        mainAppBackgroundColor: '#FFFFFF', //                                  --gd-main-app-background-color
        mainAppShadowedContent: 'rgb(0 0 0 / 8%) 0px -15px 15px 0px', //       --gd-main-app-shadowed-content
        mainActiveTabBackgroundColor: '#EEEEEE', //                            --gd-main-active-tab-background-color
        mainActiveTabColor: 'var(--gd-secondary-color)', //                    --gd-main-active-tab-color
        mainPopupBackgroundColor: '#FFFFFF', //                                --gd-main-popup-background-color

        blogsPrimaryColor: 'var(--gd-secondary-color)', //                     --gd-blogs-primary-color
        blogsPrimaryDarkColor: 'var(--gd-primary-color)', //                   --gd-blogs-primary-color
        blogsSecondaryColor: 'var(--gd-primary-color)', //                     --gd-blogs-secondary-color

        backgroundClearColor: '#F5F5F5', //                                    --gd-background-clear-color
        backgroundDarkColor: '#C9C9C9', //                                     --gd-background-dark-color

        textClearColor: '#FFFFFF', //                                          --gd-text-clear-color
        textDarkColor: '#1C1B1F', //                                           --gd-text-dark-color
        textTertiaryColor: '#FFFFFF', // same value as gd-text-clear-color //  --gd-text-tertiary-color
        textMainSubTitleColor: '#3E9097', //                                   --gd-text-main-subtitle-color
        bigTextDarkColor: '#1C1B1F', //                                        --gd-big-text-dark-color

        brickEmailColor: '#5C819E', //                                         --gd-brick-email-color
        brickEmailActiveColor: 'var(--gd-background-clear-color)', //          --gd-brick-email-active-color
        brickOneDriveColor: '#336286', //                                      --gd-brick-onedrive-color
        brickOneDriveActiveColor: 'var(--gd-background-clear-color)', //       --gd-brick-onedrive-active-color
        brickTeamsColor: '#003B68', //                                         --gd-brick-teams-color
        brickTeamsActiveColor: 'var(--gd-background-clear-color)', //          --gd-brick-teams-active-color
        brickSharepointColor: '#267379', //                                    --gd-brick-sharepoint-color
        brickSharepointActiveColor: 'var(--gd-background-clear-color)', //     --gd-brick-sharepoint-active-color
        brickVisionColor: '#58BDC5', //                                        --gd-brick-vision-color
        brickVisionActiveColor: '#58BDC5', //                                  --gd-brick-vision-active-color
        brickChatColor: '#D25711', //                                          --gd-brick-chat-color
        brickGmailColor: '#002F7D', //                                         --gd-brick-gmail-color
        brickGmailActiveColor: '#F0F6FF', //                                   --gd-brick-gmail-active-color
        brickGDriveColor: '#14675A', //                                        --gd-brick-gdrive-color
        brickGDriveActiveColor: '#F0F6FF', //                                  --gd-brick-gdrive-active-color
        brickSharedGDriveColor: '#302E9D', //                                  --gd-brick-shared-gdrive-color
        brickSharedGDriveActiveColor: '#F0F6FF', //                            --gd-brick-shared-gdrive-active-color
        brickCopiloteColor: '#A86EDD', //                                      --gd-brick-copilote-color
        brickCopiloteActiveColor: '#1C1B1F', //                                --gd-brick-copilote-active-color

        goalTextColor: 'var(--gd-text-dark-color)', //                         --gd-goal-text-color
        goalFillColor: '#FFF', //                                              --gd-goal-fill-color
        goalCarbonFootprintColor: '#D25711', //                                --gd-goal-carbon-footprint-color
        goalInfobisityColor: '#D25711', //                                     --gd-goal-infobesity-color
        goalDeconnectionTimeColor: '#D25711', //                               --gd-goal-deconnection-time-color

        badgeTextColor: 'var(--gd-text-dark-color)', //                        --gd-badge-text-color
        badgeFillColor: '#FFF', //                                             --gd-badge-fill-color
        badgeMailsOnDietColor: '#D25711', //                                   --gd-badge-mails-on-diet-color
        badgeMailsOnDietLineColor: '#D25711', //                               --gd-badge-mails-on-diet-line-color
        badgeMailsOnDietFillColor: '#D25711', //                               --gd-badge-mails-on-diet-fill-color
        badgeCleanUpColor: '#D25711', //                                       --gd-badge-cleanup-color
        badgeCleanUpLineColor: '#D25711', //                                   --gd-badge-cleanup-line-color
        badgeCleanUpFillColor: '#D25711', //                                   --gd-badge-cleanup-fill-color
        badgePlanetFriendlyColor: '#D25711', //                                --gd-badge-planet-friendly
        badgePlanetFriendlyLineColor: '#D25711', //                            --gd-badge-planet-line-friendly
        badgePlanetFriendlyFillColor: '#D25711', //                            --gd-badge-planet-fill-friendly
        badgeInternAttachmentColor: '#D25711', //                              --gd-badge-intern-attachment-color
        badgeInternAttachmentLineColor: '#D25711', //                          --gd-badge-intern-attachment-line-color
        badgeInternAttachmentFillColor: '#D25711', //                          --gd-badge-intern-attachment-fill-color
        badgeMailShotColor: '#D25711', //                                      --gd-badge-mailshot-color
        badgeMailShotLineColor: '#D25711', //                                  --gd-badge-mailshot-line-color
        badgeMailShotFillColor: '#D25711', //                                  --gd-badge-mailshot-fill-color
        badgeImageHuntColor: '#D25711', //                                     --gd-badge-imagehunt-color
        badgeImageHuntLineColor: '#D25711', //                                 --gd-badge-imagehunt-line-color
        badgeImageHuntFillColor: '#D25711', //                                 --gd-badge-imagehunt-fill-color
        badgeVideoHuntColor: '#D25711', //                                     --gd-badge-video-hunt-color
        badgeVideoHuntLineColor: '#D25711', //                                 --gd-badge-video-hunt-line-color
        badgeVideoHuntFillColor: '#D25711', //                                 --gd-badge-video-hunt-fill-color
        loaderColors: ['#002642', '#D25711', '#D25711', '#EEEEEE', '#D25711'],

        emailSendingRangeLessThanTen: '#9BCDD0', //                            --gd-email-sending-range-stick-less-than-ten-fill-color
        emailSendingRangeBetweenTenAndThirty: '#59ABB1', //                    --gd-email-sending-range-stick-beetwen-ten-and-thirty-fill-color
        emailSendingRangeMoreThanThirty: '#2D5659', //                          --gd-email-sending-range-stick-more-than-thirty-fill-color

        emailSendingRangeSmallRange: '#9BCDD0', //                             --gd-email-sending-range-small-range-color
        emailSendingRangeMediumRange: '#59ABB1', //                            --gd-email-sending-range-medium-range-color
        emailSendingRangeLargeRange: '#4d7679', //                             --gd-email-sending-range-large-range-color
        emailSendingRangeHeightRange: '#2D5659', //                            --gd-email-sending-range-height-range-color
    },
    customerLang: {
        fr: fftFr,
        en: fftEn,
    },
    goals: [EGoal.CARBON_FOOTPRINT],
    contact: {
        support: {
            name: 'Charlotte Politi',
            mail: 'cpoliti@fft.fr',
        },
        hasLinkSupport: false,
        link: '',
    },
    legalInformation: {
        cgu: 'https://leckogreet.blob.core.windows.net/lecko/Greet_CGU_Lecko_Oct_2023.pdf',
        charter: 'https://leckogreet.blob.core.windows.net/lecko/Greet_Charte_de_protection_des_donnees_personnelles_Oct_2023.pdf',
    },
    webinarImg: WebinarImg,
    goalPagesLayout: {
        coachingOnCollective: {
            tips: true,
            training: true,
            coaching: true,
        },
    },

    bugReport: defaultBugReport,
    defaultCoachingMeeting: defaultCoachingMeeting,
    coachingEvent: ECoachingEvent.WEBINAR,
    blogs: defaultBlogs
}

export default fft
