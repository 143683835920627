import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import Select, { DropdownIndicatorProps, GroupBase, SingleValue, ValueContainerProps, components } from 'react-select'
import IconAssets from '../../assets/icon/IconAssets'
import CarbonFootprintIcon from '../../assets/icon/tabs/CarbonFootprintIcon'
import QVTIcon from '../../assets/icon/tabs/QVTIcon'
import { useUser } from '../../contexts'
import { Cohort } from '../../models/Cohort'
import EGoal from '../../models/EGoal'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import { convertToRem } from '../utils'

// width in pixels
const GROUP_TAB_MIN_WIDTH = 130
const GROUP_TAB_MAX_WIDTH = 280
const SEPERATOR_WIDTH = 22
const INDIVIDUAL_TAB_MAX_WIDTH = 108
const QVT_INDIVIDUAL_TAB_MAX_WIDTH = 300
const INDIVIDUAL_TAB_MIN_WIDTH = INDIVIDUAL_TAB_MAX_WIDTH - SEPERATOR_WIDTH * 2

const useStyles = createUseStyles({
    tabs: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        height: convertToRem(51),
        borderRadius: 'var(--gd-border-small-radius)',
        backgroundColor: 'var(--gd-background-clear-color)',
    },
    tabItem: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'black',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 'bold',
        '& svg': {
            width: convertToRem(8),
            height: convertToRem(11),
            '& path': {
                stroke: 'currentcolor',
            },
        },
    },
    tabActive: {
        color: 'var(--gd-main-active-tab-color)',
    },
    groupTab: {
        marginLeft: convertToRem(9),
        minWidth: convertToRem(GROUP_TAB_MIN_WIDTH),
        maxWidth: convertToRem(GROUP_TAB_MAX_WIDTH),
    },
    individualTab: {
        width: convertToRem(INDIVIDUAL_TAB_MAX_WIDTH),
        '& div': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            '& span': {
                marginLeft: convertToRem(10),
            },
        },
        '& svg': {
            width: '15px',
            height: '15px',
        },
    },
    qvtIndividualTab: {
        width: convertToRem(QVT_INDIVIDUAL_TAB_MAX_WIDTH),
        marginLeft: convertToRem(9),
    },
    qvtIndividualTabLabel: {
        display: 'unset',
    },
    individualTabNoIndicators: {
        width: 'unset',
        minWidth: convertToRem(INDIVIDUAL_TAB_MAX_WIDTH),
        '& div svg': {
            width: convertToRem(24),
            height: convertToRem(24),
            '& circle': {
                stroke: 'unset',
                fill: 'var(--gd-background-dark-color)',
            },
            '& path': {
                stroke: 'white',
            },
        },
    },
    bar: {
        position: 'absolute',
        bottom: 0,
        marginTop: convertToRem(8),
        width: '100%',
        height: convertToRem(5),
        borderTopLeftRadius: convertToRem(5),
        borderTopRightRadius: convertToRem(5),
        backgroundColor: 'var(--gd-main-active-tab-color)',
    },
    seperator: {
        width: convertToRem(SEPERATOR_WIDTH),
    },
    individualGoalSelectorContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    individualGoalSelector: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            marginRight: convertToRem(10),
            cursor: 'pointer',
        },
        '& svg circle': {
            fill: 'var(--gd-background-dark-color)',
            transition: 'fill 300ms ease',
        },
        '& svg.activated circle': {
            fill: 'var(--gd-primary-color)',
        },
    },
    [MediaQueryBreakpoints.MOBILE]: {
        tabs: {
            width: `calc(100% - ${convertToRem(10)})`,
        },
        individualTab: {
            marginRight: convertToRem(9),
            width: convertToRem(INDIVIDUAL_TAB_MIN_WIDTH),
        },
        qvtIndividualTabLabel: {
            display: 'none',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        tabs: {
            width: `calc(100% - ${convertToRem(40)})`,
        },
    },
})

const useSelectStyles = createUseStyles({
    container: {
        padding: 'unset !important',
    },
    control: {
        paddingInline: `${convertToRem(8)} !important`,
        minWidth: `${convertToRem(GROUP_TAB_MIN_WIDTH)} !important`,
        maxWidth: `${convertToRem(GROUP_TAB_MAX_WIDTH)} !important`,
        columnGap: `${convertToRem(15)} !important`,
        backgroundColor: 'transparent !important',
        borderWidth: '0rem !important',
        boxShadow: 'none !important',
    },
    valueContainer: {
        padding: 'unset !important',
        '& svg': {
            width: convertToRem(15.45),
            height: convertToRem(13.39),
            gridArea: '1/1/1/1',
        },
    },
    menu: {
        width: `${convertToRem(GROUP_TAB_MAX_WIDTH)} !important`,
    },
    option: {
        backgroundColor: 'unset !important',
        color: 'var(--gd-text-dark-color) !important',
        fontWeight: 'normal !important',
    },
    optionFocused: {
        backgroundColor: 'var(--gd-background-dark-color) !important',
        color: 'var(--gd-text-dark-color) !important',
        fontWeight: 'normal !important',
    },
    dropdownIndicator: {
        padding: 'unset !important',
        color: 'white !important',
        '& svg': {
            width: `${convertToRem(24)} !important`,
            height: `${convertToRem(24)} !important`,
        },
    },
    dropdownIndicatorActive: {
        '& svg circle': {
            fill: 'var(--gd-secondary-color) !important',
        },
    },
    singleValue: {
        marginLeft: `${convertToRem(25)} !important`,
        color: 'currentcolor !important',
    },
})

interface GroupTabProps {
    active: boolean
    isDisabled: boolean
    noOptionsMessage: string
    options: Array<{ value: Cohort | undefined; label: string }>
    value: {
        value: Cohort | undefined
        label: string
    }
    onClick: () => void
    onChange: (newValue: SingleValue<{ value: Cohort | undefined; label: string | undefined }>) => void
}

interface IndividualTabProps {
    active: boolean
    label: string
    hasIndicators: boolean
    eGoal: EGoal
    url: string
    onClick: () => void
}

interface ManagerTabProps {
    active: boolean
    label: string
    hasGoalManagerAccess: boolean
    eGoal: EGoal
    url: string
    onClick: () => void
}

export interface Props {
    className: string
    groupTab: GroupTabProps
    individualTab: IndividualTabProps
    managerTab: ManagerTabProps
    popupComponent: JSX.Element | null
    canSwitchGoal: boolean
}

export default function NewTabs({ className, groupTab, individualTab, managerTab, popupComponent, canSwitchGoal }: Readonly<Props>) {
    const styles = useStyles()
    const history = useHistory()

    return (
        <div className={classNames(styles.tabs, className)}>
            {individualTab.eGoal === EGoal.QVT ? (
                <>
                    <QVTIndividualTab {...individualTab} />
                    <QVTManagerTab {...managerTab} />
                </>
            ) : (
                <>
                    <GroupTab {...groupTab} />
                    <div className={styles.seperator}></div>
                    <IndividualTab {...individualTab} />
                    {popupComponent}
                </>
            )}
            {individualTab.active && canSwitchGoal ? (
                <div className={styles.individualGoalSelectorContainer}>
                    <div className={styles.individualGoalSelector}>
                        <CarbonFootprintIcon
                            activated={individualTab.eGoal === EGoal.CARBON_FOOTPRINT}
                            onClick={() => history.push(`${individualTab.url}/${EGoal.CARBON_FOOTPRINT}/individual`)}
                        />
                        <QVTIcon activated={individualTab.eGoal === EGoal.QVT} onClick={() => history.push(`${individualTab.url}/${EGoal.QVT}/individual`)} />
                    </div>
                </div>
            ) : null}
        </div>
    )
}

function QVTIndividualTab({ hasIndicators, active, onClick }: Readonly<IndividualTabProps>) {
    const styles = useStyles()
    const { lang } = useUser()

    return (
        <div
            className={classNames(
                styles.tabItem,
                styles.individualTab,
                styles.qvtIndividualTab,
                active ? styles.tabActive : '',
                hasIndicators ? '' : styles.individualTabNoIndicators,
            )}
            onClick={onClick}
        >
            <div>
                <IconAssets.UserIcon />
                <span className={styles.qvtIndividualTabLabel}>{lang.goal.individual.button.qvt}</span>
            </div>
            {active ? <Bar /> : null}
        </div>
    )
}

function QVTManagerTab({ label, active, onClick, hasGoalManagerAccess }: Readonly<ManagerTabProps>) {
    const styles = useStyles()
    if (!hasGoalManagerAccess) {
        return null
    }

    return (
        <div className={classNames(styles.tabItem, styles.individualTab, styles.qvtIndividualTab, active ? styles.tabActive : '')} onClick={onClick}>
            <div>
                <IconAssets.GroupIcon />
                <span className={styles.qvtIndividualTabLabel}>{label}</span>
            </div>
            {active ? <Bar /> : null}
        </div>
    )
}

function GroupTab({ active, value, noOptionsMessage, options, isDisabled, onClick, onChange }: Readonly<GroupTabProps>) {
    const styles = useStyles()
    const selectStyles = useSelectStyles()

    return (
        <div className={classNames(styles.tabItem, styles.groupTab, active ? styles.tabActive : '')} onClick={onClick}>
            <Select
                components={{ ValueContainer, DropdownIndicator, IndicatorSeparator: () => null }}
                value={value}
                placeholder=''
                isSearchable={false}
                isMulti={false}
                noOptionsMessage={() => noOptionsMessage}
                options={options}
                onChange={onChange}
                isDisabled={isDisabled}
                classNames={{
                    container: () => classNames(selectStyles.container),
                    control: () => classNames(selectStyles.control),
                    valueContainer: () => classNames(selectStyles.valueContainer),
                    singleValue: () => classNames(selectStyles.singleValue),
                    dropdownIndicator: () => classNames(selectStyles.dropdownIndicator, active ? selectStyles.dropdownIndicatorActive : ''),
                    menu: () => classNames(selectStyles.menu),
                    option: ({ isFocused }: any) => classNames(isFocused ? selectStyles.optionFocused : selectStyles.option),
                }}
            />
            {active ? <Bar /> : null}
        </div>
    )
}

function IndividualTab({ label, hasIndicators, active, onClick }: Readonly<IndividualTabProps>) {
    const styles = useStyles()
    return (
        <div className={classNames(styles.tabItem, styles.individualTab, active ? styles.tabActive : '', hasIndicators ? '' : styles.individualTabNoIndicators)} onClick={onClick}>
            <div>
                {hasIndicators ? <IconAssets.UserIcon /> : <IconAssets.PlusIcon />}
                <span>{label}</span>
            </div>
            {active ? <Bar /> : null}
        </div>
    )
}

function Bar() {
    const styles = useStyles()
    return <div className={styles.bar}></div>
}

// Custom Select components

function ValueContainer(
    props: Readonly<
        ValueContainerProps<
            {
                value: Cohort | undefined
                label: string
            },
            false,
            GroupBase<{
                value: Cohort | undefined
                label: string
            }>
        >
    >,
) {
    return (
        <components.ValueContainer {...props}>
            <IconAssets.GroupIcon />
            {props.children}
        </components.ValueContainer>
    )
}

function DropdownIndicator(
    props: Readonly<
        DropdownIndicatorProps<
            {
                value: Cohort | undefined
                label: string
            },
            false,
            GroupBase<{
                value: Cohort | undefined
                label: string
            }>
        >
    >,
) {
    return (
        <components.DropdownIndicator {...props}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='12' cy='12' r='12' fill='var(--gd-background-dark-color)' />
                <path d='M8 11L12 15L16 11' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
        </components.DropdownIndicator>
    )
}
