import { ReactNode, useState } from 'react'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import IllustrationAssets from '../../assets/illustration/IllustrationAssets'
import { useStore, useUser } from '../../contexts'
import { BadgeType } from '../../models/Badge'
import { ChallengeStatus } from '../../models/UserPayload'
import { useFetch, usePut } from '../../services/api/useFetch'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import Popup from '../Popup'
import { toDayLiteralMonthString, toDayNumericString } from '../dateUtils'
import { GreetButton } from '../share/GreetButton'
import { Lang } from '../../models'

const useStyles = createUseStyles({
    popup: {
        maxWidth: '500px !important',
    },
    popupCloseButton: {
        display: 'none !important',
    },
    wrapper: {
        display: 'grid',
        width: '100%',
        height: '100%',
        backgroundColor: 'var(--gd-main-popup-background-color)',
    },
    congratulation: {
        width: '100%',
        height: '100%',
        maxWidth: '500px',
        gridArea: '1 / 1 / 2 / 2',
    },
    view: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        maxWidth: '500px',
        padding: '25px 0px',
        rowGap: '25px',
        gridArea: '1 / 1 / 2 / 2',
    },
    title: {
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
        color: 'var(--gd-secondary-color)',
    },
    iconDescription: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '7px',
        width: '65%',
    },
    iconsWrapper: {
        display: 'grid',
    },
    challengeIcon: {
        width: '148px',
        height: '148px',
        gridArea: '1 / 1 / 2 / 2',
    },
    failedSucceededIcon: {
        position: 'relative',
        left: '100px',
        width: '47px',
        height: '47px',
        gridArea: '1 / 1 / 2 / 2',
    },
    description: {
        fontSize: 'var(--gd-size-body-text)',
        color: 'var(--gd-text-dark-color)',
        textAlign: 'center',
    },
    button: {
        width: '200px',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        popup: {
            maxWidth: '90% !important',
            maxHeight: '320px !important',
            borderRadius: 'var(--gd-border-normal-radius) !important',
        },
        congratulation: {
            height: '80%',
            maxWidth: '100%',
        },
        view: {
            maxWidth: 'unset',
            padding: '15px 0px 0px 0px',
        },
        title: {
            maxWidth: '80%',
            textAlign: 'center',
        },
        challengeIcon: {
            width: '100px',
            height: '100px',
        },
        failedSucceededIcon: {
            left: '70px',
            width: '37px',
            height: '37px',
        },
    },
})

export default function ChallengeEndPopup() {
    const { state } = useStore()
    const { user, lang } = useUser()
    const { loading, data: challengeStatuses } = useFetch({
        endpoint: 'challenge-end-popups',
        params: { userId: user?.userId },
    })

    const styles = useStyles()
    const [index, setIndex] = useState<number>(0)
    const challengeStatus: ChallengeStatus = challengeStatuses == null || challengeStatuses.length < index ? null : challengeStatuses[index]
    const cohort = state.cohortsPayload?.find((cohort) => cohort.cohortId === challengeStatus?.cohortId)
    const { doPut } = usePut({ endpoint: 'hide-challenge-end-popup', params: { challengeStatusId: challengeStatus?.id }, enableTaostSuccess: false })
    const [challengeIcon, setChallengeIcon] = useState<ReactNode>()

    if (loading || challengeStatus == null || cohort == null) {
        return null
    }

    const onClose = () => {
        setIndex((prev) => prev + 1)
        doPut(null)
    }

    const text = lang.challengeEndPopup.text(challengeStatus.awarded, cohort.cohortName, getPeriod(challengeStatus, lang))

    if (!challengeIcon && challengeStatus) {
        setChallengeIcon(getChallengeIcon(challengeStatus.badgeType))
    }

    return (
        <Popup className={styles.popup} closable={false} onCloseRequest={onClose}>
            <View
                title={text.title}
                description={text.description}
                icon={challengeIcon}
                buttonLabel={lang.challengeEndPopup.button}
                earned={challengeStatus.awarded}
                onClick={onClose}
            />
        </Popup>
    )
}

interface ViewProps {
    title: string
    icon: ReactNode
    description: ReactNode
    buttonLabel: string
    earned: boolean
    onClick: () => void
}

function View({ title, icon, description, buttonLabel, earned, onClick }: ViewProps) {
    const styles = useStyles()

    return (
        <div className={styles.wrapper}>
            {earned && (
                <div className={styles.congratulation}>
                    <IllustrationAssets.ChallengeEndCongratulation />
                </div>
            )}
            <div className={styles.view}>
                <div className={styles.title}>{title}</div>
                <div className={styles.iconDescription}>
                    <div className={styles.iconsWrapper}>
                        <div className={styles.challengeIcon}>{icon}</div>
                        <div className={styles.failedSucceededIcon}>{earned ? <SucceededIcon /> : <FailedIcon />}</div>
                    </div>
                    <div className={styles.description}>{description}</div>
                </div>
                <GreetButton className={styles.button} label={buttonLabel} onClick={onClick} />
            </div>
        </div>
    )
}

function FailedIcon() {
    return (
        <svg width='100%' height='100%' viewBox='0 0 47 47' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='22.0313' cy='24.9766' r='22.0313' fill='white' />
            <circle cx='24.9688' cy='22.0391' r='22.0313' fill='var(--gd-negative-color)' />
            <path d='M17.9277 29.3477L31.2213 16.054' stroke='white' strokeWidth='3.61538' strokeLinecap='round' />
            <path d='M31.2207 29.3477L17.9271 16.054' stroke='white' strokeWidth='3.61538' strokeLinecap='round' />
            <path d='M17.9277 29.3477L31.2213 16.054' stroke='white' strokeWidth='3.61538' strokeLinecap='round' />
            <path d='M31.2207 29.3477L17.9271 16.054' stroke='white' strokeWidth='3.61538' strokeLinecap='round' />
        </svg>
    )
}

function SucceededIcon() {
    return (
        <svg width='100%' height='100%' viewBox='0 0 47 47' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='22.0313' cy='24.9766' r='22.0313' fill='white' />
            <circle cx='24.9688' cy='22.0391' r='22.0313' fill='var(--gd-positive-color)' />
            <path d='M15.8984 22.2656L22.5937 28.9608L33.7524 15.5703' stroke='white' strokeWidth='3.61538' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    )
}

function getChallengeIcon(type: BadgeType): ReactNode {
    if (type === BadgeType.MAIL) {
        return <IconAssets.Attachement />
    }
    if (type === BadgeType.DRIVE) {
        return <IconAssets.Garbage />
    }
    if (type === BadgeType.INTERN_ATTACHMENT) {
        return <IconAssets.InternAttachment />
    }
    if (type === BadgeType.MAILSHOT) {
        return <IconAssets.MailShot />
    }
    if (type === BadgeType.IMAGE_HUNT) {
        return <IconAssets.ImageHunt />
    }
    if (type === BadgeType.VIDEO_HUNT) {
        return <IconAssets.VideoHunt />
    }

    return null
}

function getPeriod(challengeStatus: ChallengeStatus, lang: Lang): { from: string; to: string } {
    return {
        from: toDayNumericString(new Date(challengeStatus.startDate), lang),
        to: toDayLiteralMonthString(new Date(challengeStatus.endDate), lang.locale),
    }
}
