import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { useInstance, useUser } from '../../contexts'
import { Lang } from '../../models'
import { Brick, BrickTypes } from '../../models/Brick'
import Payload from '../../models/Payload'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import { substractYears, toYearString } from '../dateUtils'
import { convertToGCO2, numberFormatter, octetsConverter } from '../utils'
import EvolutionBox, { EvolutionBoxProps } from './EvolutionBox'
import UsageBox, { UsageBoxProps } from './UsageBox'
import { GreetFeaturingTarget } from '../share/GreetFeaturingComponent'

interface BrickDoughnutDetailsProps {
    bricks: Brick[]
    payload: Payload
    activeBrick: BrickTypes
}

const useStyles = createUseStyles({
    brickDoughnutDetails: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        rowGap: '20px',
    },
    correspondenceTitle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '15px',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        color: 'var(--gd-text-dark-color)',
    },
    usagesBoxes: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
        rowGap: '16px',
        columnGap: '25px',
        minWidth: '355px',
    },
    usageBoxe: {
        display: 'flex',
        justifyContent: 'center',
        width: '165px',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        usagesBoxes: {
            display: 'flex',
            flexWrap: 'wrap',
            rowGap: '16px',
            columnGap: '10px',
            justifyContent: 'space-around',
        },
        usageBoxe: {
            flexBasis: '40%',
            width: '100%',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        usagesBoxes: {
            display: 'flex',
            flexWrap: 'wrap',
            rowGap: '16px',
            columnGap: '10px',
            justifyContent: 'space-around',
        },
        usageBoxe: {
            flexBasis: '20%',
            width: '100%',
        },
    },
})

const BrickDoughnutDetails = ({ payload, bricks, activeBrick }: BrickDoughnutDetailsProps) => {
    const styles = useStyles()
    const { lang } = useUser()
    const { evolutionBoxPros, usagesBox } = buildDetailsProps(lang, payload, bricks, activeBrick)

    return (
        <div className={styles.brickDoughnutDetails}>
            <EvolutionBox
                startDate={payload.startDate}
                carbonImpact={evolutionBoxPros.carbonImpact}
                growthPercentage={evolutionBoxPros.growthPercentage}
                comparedToText={evolutionBoxPros.comparedToText}
                unit={evolutionBoxPros.unit}
                backgroundColor={evolutionBoxPros.backgroundColor}
                textColor={evolutionBoxPros.textColor}
                noActivityLastYear={evolutionBoxPros.noActivityLastYear}
            />
            <div className={styles.correspondenceTitle}>{lang.goal.carbonFootprint.correspondence}</div>
            <div className={styles.usagesBoxes}>
                {usagesBox.map((itemProps: UsageBoxProps, index) => (
                    <div key={`${index}-${itemProps.secondaryColor}`} className={styles.usageBoxe}>
                        <UsageBox
                            measureValue={itemProps.measureValue}
                            measureUnit={itemProps.measureUnit}
                            measureDesciption={itemProps.measureDesciption}
                            textColor={itemProps.textColor}
                            primayColor={itemProps.primayColor}
                            secondaryColor={itemProps.secondaryColor}
                            icon={itemProps.icon}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default BrickDoughnutDetails

interface DetailsCalculatedProps {
    evolutionBoxPros: EvolutionBoxProps
    usagesBox: UsageBoxProps[]
}

const buildDetailsProps = (lang: Lang, payload: Payload, bricks: Brick[], activeBrick: BrickTypes): DetailsCalculatedProps => {
    const { customer } = useInstance()
    let usagesBoxes: UsageBoxProps[]
    const defaulUsageBoxProps = {
        primayColor: 'var(--gd-background-clear-color)',
        icon: <IconAssets.Send />,
    }
    let brickColor
    let textColor
    let evolutionBoxComparedTo
    let convertedCarbonImpactValue
    let carbonImpactGrowthPercentageFromLastYear
    let convertedMetrics
    let convertedDriveVersionsVolumeMetrics
    let noActivityLastYear = false

    switch (activeBrick) {
        case BrickTypes.GMAIL:
        case BrickTypes.EMAIL:
            convertedCarbonImpactValue = convertToGCO2(payload.mail.carbonImpact)
            carbonImpactGrowthPercentageFromLastYear = payload.mail.carbonImpactGrowthPercentageFromLastYear
            noActivityLastYear = payload.mail.noActivityLastYear
            convertedMetrics = octetsConverter(payload.mail.mailAttachmentsSizeSent, lang.code)
            evolutionBoxComparedTo = lang.goal.carbonFootprint.emailBrick.comparedToLastYear(toYearString(substractYears(payload.calculationDate, 1)))
            if (activeBrick === BrickTypes.GMAIL) {
                brickColor = 'var(--gd-brick-gmail-color)'
                textColor = 'var(--gd-brick-gmail-active-color)'
            } else {
                brickColor = 'var(--gd-brick-email-color)'
                textColor = 'var(--gd-brick-email-active-color)'
            }
            usagesBoxes = [
                {
                    measureValue: `${payload.mail.mailsSent}`,
                    measureDesciption:
                        payload.mail.mailsSent > 1 //
                            ? lang.goal.carbonFootprint.emailBrick.usage.mails
                            : lang.goal.carbonFootprint.emailBrick.usage.mail,
                    secondaryColor: brickColor,
                    textColor: textColor,
                    ...defaulUsageBoxProps,
                } as UsageBoxProps,
                {
                    measureValue: `${payload.mail.mailAttachmentsSent}`,
                    measureDesciption:
                        payload.mail.mailAttachmentsSent > 1 //
                            ? lang.goal.carbonFootprint.emailBrick.usage.attachments
                            : lang.goal.carbonFootprint.emailBrick.usage.attachment,
                    secondaryColor: brickColor,
                    textColor: textColor,
                    ...defaulUsageBoxProps,
                    icon: <IconAssets.AttachementClip />,
                } as UsageBoxProps,
                {
                    measureValue: `${numberFormatter(payload.mail.destinatairePerMail)}`,
                    measureDesciption:
                        payload.mail.mailAttachmentsSent > 1 //
                            ? lang.goal.carbonFootprint.emailBrick.usage.receipts
                            : lang.goal.carbonFootprint.emailBrick.usage.receipt,
                    secondaryColor: brickColor,
                    textColor: textColor,
                    ...defaulUsageBoxProps,
                    icon: <IconAssets.Destinataire />,
                } as UsageBoxProps,
                {
                    measureValue: numberFormatter(convertedMetrics.value),
                    measureUnit: convertedMetrics.measured,
                    measureDesciption: lang.goal.carbonFootprint.emailBrick.usage.volumeAttachment,
                    ...defaulUsageBoxProps,
                    secondaryColor: brickColor,
                    textColor: textColor,
                    icon: <IconAssets.AttachementFileVolume />,
                } as UsageBoxProps,
            ]
            break
        case BrickTypes.GDRIVE:
        case BrickTypes.ONEDRIVE:
            convertedCarbonImpactValue = convertToGCO2(payload.drive.carbonImpact)
            carbonImpactGrowthPercentageFromLastYear = payload.drive.carbonImpactGrowthPercentageFromLastYear
            convertedMetrics = octetsConverter(payload.drive.sizeOfFilesOnDisk, lang.code)
            convertedDriveVersionsVolumeMetrics = octetsConverter(payload.drive.cumulativeVersionsVolume, lang.code)
            evolutionBoxComparedTo = lang.goal.carbonFootprint.oneDriveBrick.comparedToLastYear(toYearString(substractYears(payload.calculationDate, 1)))
            if (activeBrick === BrickTypes.GDRIVE) {
                brickColor = 'var(--gd-brick-gdrive-color)'
                textColor = 'var(--gd-brick-gdrive-active-color)'
            } else {
                brickColor = 'var(--gd-brick-onedrive-color)'
                textColor = 'var(--gd-brick-onedrive-active-color)'
            }
            usagesBoxes = [
                {
                    measureValue: `${numberFormatter(payload.drive.numberOfFilesOnDisk)}`,
                    measureDesciption:
                        payload.drive.numberOfFilesOnDisk > 1 //
                            ? lang.goal.carbonFootprint.oneDriveBrick.usage.files
                            : lang.goal.carbonFootprint.oneDriveBrick.usage.file,
                    ...defaulUsageBoxProps,
                    secondaryColor: brickColor,
                    textColor: textColor,
                    icon: <IconAssets.File />,
                } as UsageBoxProps,
                {
                    measureValue: String(convertedMetrics.value),
                    measureUnit: convertedMetrics.measured,
                    measureDesciption: lang.goal.carbonFootprint.oneDriveBrick.usage.volumeFiles,
                    ...defaulUsageBoxProps,
                    secondaryColor: brickColor,
                    textColor: textColor,
                    icon: <IconAssets.AttachementFileVolume />,
                } as UsageBoxProps,
            ]

            if (customer.featuring?.includes(GreetFeaturingTarget.DRIVE_VERSION)) {
                usagesBoxes.push(
                    {
                        // To be replaced with real values
                        measureValue: numberFormatter(payload.drive.numberOfCumulativeVersions),
                        measureDesciption: lang.goal.carbonFootprint.oneDriveBrick.usage.versionFiles,
                        ...defaulUsageBoxProps,
                        secondaryColor: brickColor,
                        textColor: textColor,
                        icon: <IconAssets.Destinataire />,
                    } as UsageBoxProps,
                    {
                        // To be replaced with real values
                        measureValue: String(convertedDriveVersionsVolumeMetrics.value),
                        measureUnit: convertedDriveVersionsVolumeMetrics.measured,
                        measureDesciption: lang.goal.carbonFootprint.oneDriveBrick.usage.versionVolumeFiles,
                        ...defaulUsageBoxProps,
                        secondaryColor: brickColor,
                        textColor: textColor,
                        icon: <IconAssets.AttachementClip />,
                    } as UsageBoxProps,
                )
            }

            break
        case BrickTypes.TEAMS:
            convertedCarbonImpactValue = convertToGCO2(payload.teams.carbonImpact)
            carbonImpactGrowthPercentageFromLastYear = payload.teams.carbonImpactGrowthPercentageFromLastYear
            convertedMetrics = octetsConverter(payload.teams.sizeOfFilesOnDisk, lang.code)
            evolutionBoxComparedTo = lang.goal.carbonFootprint.teamsBrick.comparedToLastYear(toYearString(substractYears(payload.calculationDate, 1)))
            brickColor = 'var(--gd-brick-teams-color)'
            textColor = 'var(--gd-brick-teams-active-color)'
            usagesBoxes = [
                {
                    measureValue: numberFormatter(payload.teams.nbChannelActivities),
                    measureDesciption:
                        payload.teams.nbChannelActivities > 1 //
                            ? lang.goal.carbonFootprint.teamsBrick.usage.activities
                            : lang.goal.carbonFootprint.teamsBrick.usage.activity,
                    secondaryColor: brickColor,
                    textColor: textColor,
                    ...defaulUsageBoxProps,
                    icon: <IconAssets.Monitor />,
                } as UsageBoxProps,
                {
                    measureValue: numberFormatter(payload.teams.numberOfNewFilesCreated),
                    measureDesciption:
                        payload.teams.numberOfNewFilesCreated > 1 //
                            ? lang.goal.carbonFootprint.teamsBrick.usage.files
                            : lang.goal.carbonFootprint.teamsBrick.usage.file,
                    secondaryColor: brickColor,
                    textColor: textColor,
                    ...defaulUsageBoxProps,
                    icon: <IconAssets.File />,
                } as UsageBoxProps,
                {
                    measureValue: String(convertedMetrics.value),
                    measureUnit: convertedMetrics.measured,
                    measureDesciption: lang.goal.carbonFootprint.teamsBrick.usage.volumeFiles,
                    secondaryColor: brickColor,
                    textColor: textColor,
                    ...defaulUsageBoxProps,
                    icon: <IconAssets.AttachementFileVolume />,
                } as UsageBoxProps,
            ]
            break
        case BrickTypes.SHAREDGDRIVE:
            convertedCarbonImpactValue = convertToGCO2(payload.sharedDrive.carbonImpact)
            carbonImpactGrowthPercentageFromLastYear = payload.sharedDrive.carbonImpactGrowthPercentageFromLastYear
            convertedMetrics = octetsConverter(payload.sharedDrive.sizeOfFilesOnDisk, lang.code)
            evolutionBoxComparedTo = lang.goal.carbonFootprint.oneDriveBrick.comparedToLastYear(toYearString(substractYears(payload.calculationDate, 1)))
            brickColor = 'var(--gd-brick-shared-gdrive-color)'
            textColor = 'var(--gd-brick-shared-gdrive-active-color)'
            usagesBoxes = [
                {
                    measureValue: `${numberFormatter(payload.sharedDrive.numberOfFilesOnDisk)}`,
                    measureDesciption:
                        payload.sharedDrive.numberOfFilesOnDisk > 1 //
                            ? lang.goal.carbonFootprint.oneDriveBrick.usage.files
                            : lang.goal.carbonFootprint.oneDriveBrick.usage.file,
                    ...defaulUsageBoxProps,
                    secondaryColor: brickColor,
                    textColor: textColor,
                } as UsageBoxProps,
                {
                    measureValue: String(convertedMetrics.value),
                    measureUnit: convertedMetrics.measured,
                    measureDesciption: lang.goal.carbonFootprint.oneDriveBrick.usage.volumeFiles,
                    ...defaulUsageBoxProps,
                    secondaryColor: brickColor,
                    textColor: textColor,
                } as UsageBoxProps,
            ]
            break
        case BrickTypes.COPILOTE:
            convertedCarbonImpactValue = convertToGCO2(payload.copilote?.carbonImpact ?? 0)
            carbonImpactGrowthPercentageFromLastYear = payload.copilote?.carbonImpactGrowthPercentageFromLastYear ?? 0
            evolutionBoxComparedTo = lang.goal.carbonFootprint.copiloteBrick.comparedToLastYear('30')
            brickColor = 'var(--gd-brick-copilote-color)'
            textColor = 'var(--gd-brick-copilote-active-color)'
            usagesBoxes = [
                {
                    measureValue: `${numberFormatter(payload.copilote?.numberOfTranscriptions ?? 0)}`,
                    measureDesciption:
                        payload.copilote?.numberOfTranscriptions > 1 //
                            ? lang.goal.carbonFootprint.copiloteBrick.usage.transcription
                            : lang.goal.carbonFootprint.copiloteBrick.usage.transcriptions,
                    ...defaulUsageBoxProps,
                    secondaryColor: brickColor,
                    textColor: textColor,
                    icon: <IconAssets.Transcript />,
                } as UsageBoxProps,
            ]
            break
        default:
            convertedCarbonImpactValue = convertToGCO2(0)
            carbonImpactGrowthPercentageFromLastYear = 0
            evolutionBoxComparedTo = '-'
            usagesBoxes = []
    }

    return {
        evolutionBoxPros: {
            startDate: payload.startDate,
            carbonImpact: convertedCarbonImpactValue.value,
            growthPercentage: carbonImpactGrowthPercentageFromLastYear,
            noActivityLastYear,
            comparedToText: evolutionBoxComparedTo,
            unit: convertedCarbonImpactValue.measured,
            backgroundColor: brickColor,
            textColor: textColor,
        } as EvolutionBoxProps,
        usagesBox: usagesBoxes,
    } as DetailsCalculatedProps
}
